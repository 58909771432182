export enum VariableType {
  Date = "date",
  DateArray = "date_array",
  Number = "number",
  NumberArray = "number_array",
  Money = "money",
  MoneyArray = "money_array",
}

export const variableTypeChoices = (
  Object.keys(VariableType) as (keyof typeof VariableType)[]
).map((key) => ({
  id: VariableType[key],
  name: VariableType[key],
}));
