import React from "react";
import {
  DeleteWithConfirmButton,
  ReferenceManyField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  Toolbar,
  ToolbarClasses,
  useShowController,
} from "react-admin";

import { DatapointData } from "../../../../types/models";
import { FormType } from "../../components/CreateOrEdit";
import CalculationDatagrid from "../calculations/components/CalculationDatagrid";
import DatapointOptionDatagrid from "../datapoint_options/components/DatapointOptionDatagrid";
import DatapointOptionFormButton from "../datapoint_options/components/DatapointOptionFormButton";
import NodeDatagrid from "../nodes/components/NodeDatagrid";

import { datapointTypeChoices } from "./lib/types";

const DatapointShow = () => {
  const { record } = useShowController<DatapointData>();

  if (!record) return null;

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="slug" />
        <SelectField choices={datapointTypeChoices} source="datapoint_type" />
        {record.datapoint_type === "options" && (
          <>
            <ReferenceManyField
              label="Datapoints options"
              reference="datapoint_options"
              sort={{ field: "value", order: "ASC" }}
              target="datapoint"
            >
              <DatapointOptionDatagrid />
            </ReferenceManyField>
            <DatapointOptionFormButton type={FormType.CreateForm} />
          </>
        )}
        <ReferenceManyField
          label="Nodes"
          reference="nodes"
          sort={{ field: "slug", order: "ASC" }}
          target="datapoint"
        >
          <NodeDatagrid />
        </ReferenceManyField>
        <ReferenceManyField
          label="Calculations"
          reference="calculations"
          target="datapoint"
        >
          <CalculationDatagrid rowClick="edit" />
        </ReferenceManyField>
      </SimpleShowLayout>
      <Toolbar>
        <div className={ToolbarClasses.defaultToolbar}>
          <div />
          <DeleteWithConfirmButton mutationMode="pessimistic" />
        </div>
      </Toolbar>
    </Show>
  );
};

export default DatapointShow;
