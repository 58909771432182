import ToastuiEditor from "@toast-ui/editor";
import { PluginFn, WidgetRule } from "@toast-ui/editor/types/editor";
import { useEffect, useMemo, useState } from "react";
import { useGetList } from "react-admin";

import { VariableData } from "../../../types/models";

const variableInterpolationRule =
  /<<([^\s>]+)=([^\s>]+)=([^\s>]+)=([^\s>]+)=([^\s>]+)>>/;

export const offsetString = (
  offsetYears: string | null,
  offsetMonths: string | null,
  offsetDays: string | null
) => {
  const offsets = [
    offsetYears !== "0" && `${offsetYears} years`,
    offsetMonths !== "0" && `${offsetMonths} months`,
    offsetDays !== "0" && `${offsetDays} days`,
  ].filter(Boolean);

  if (offsets.length == 0) {
    return " (no offset)";
  }

  return ` (offset: ${offsets.join(", ")})`;
};
const useVariableInterpolationPlugin = (
  editor?: ToastuiEditor
): [PluginFn, WidgetRule | false, boolean, () => void] => {
  const [showDialog, setShowDialog] = useState(false);
  const handleClose = () => setShowDialog(false);

  useEffect(() => {
    if (editor) {
      const command = () => {
        setShowDialog(true);

        return true;
      };
      editor.addCommand("markdown", "variableInterpolationDialog", command);
      editor.addCommand("wysiwyg", "variableInterpolationDialog", command);
    }
  }, [editor]);

  const { data: variables } = useGetList<VariableData>("variables", {
    pagination: { page: 1, perPage: 500 },
  });

  const hasData = !!variables;
  const variableInterpolationWidget = hasData && {
    rule: variableInterpolationRule,
    toDOM: (text: string) => {
      const matched = text.match(variableInterpolationRule);
      const span = document.createElement("span");
      span.style.border = "1px solid lightgray";
      span.style.borderRadius = "8px";
      span.style.padding = "0 4px";

      if (matched) {
        const [
          _,
          variableId,
          format,
          offset_years,
          offset_months,
          offset_days,
        ] = matched;
        const matchedVar = variables.find(
          (variable) => variable.id.toString() === variableId
        );
        span.innerHTML = matchedVar?.name_with_value ?? "missing variable";
        span.innerHTML += offsetString(
          offset_years,
          offset_months,
          offset_days
        );
        span.innerHTML += ` (${format})`;
      }

      return span;
    },
  };

  const variableInterpolationPlugin = useMemo<PluginFn>(
    () => () => ({
      toolbarItems: [
        {
          groupIndex: 0,
          item: {
            className: "toastui-editor-toolbar-icons",
            command: "variableInterpolationDialog",
            name: "variable",
            style: { backgroundImage: "none", color: "gray", fontSize: "18px" },
            text: "[y]",
            tooltip: "Variable",
          },
          itemIndex: 1,
        },
      ],
    }),
    []
  );

  return [
    variableInterpolationPlugin,
    variableInterpolationWidget,
    showDialog,
    handleClose,
  ];
};

export default useVariableInterpolationPlugin;
