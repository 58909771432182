import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

import { datapointTypeChoices } from "./lib/types";

const DatapointCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="slug" />
      <SelectInput
        choices={datapointTypeChoices}
        source="datapoint_type"
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

export default DatapointCreate;
