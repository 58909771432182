import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, Stack, Tooltip } from "@mui/material";
import React, { FC, PropsWithChildren, ReactElement, useState } from "react";
import {
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useGetManyReference,
  useRecordContext,
  useShowController,
} from "react-admin";

import { NodeAnswerButtonData, NodeData } from "../../../../../types/models";
import { formatEuro } from "../../../lib/numbers";
import { DatapointType } from "../../datapoints/lib/types";

import NodeAnswerButtonFormButton from "./NodeAnswerButtonFormButton";
import NodeAnswerFormDialog from "./NodeAnswerFormDialog";

const rangeRawValue = (record: Record<string, any>, source: string) => {
  if (record[`${source}_type`] == "variable") {
    return record[`${source}_variable_attributes`].value ?? "missing";
  }

  return record[source] ?? "infinite";
};

const RangeTooltip = ({ source }: { source: string }) => {
  const record = useRecordContext();

  const value = rangeRawValue(record, source);

  if (
    record.datapoint_type == "money_range" &&
    !["missing", "infinite"].includes(value)
  ) {
    return formatEuro(value / 100);
  }

  return value;
};

const tooltipRenderers: {
  [datapointType in DatapointType]: () => ReactElement;
} = {
  [DatapointType.Boolean]: () => <BooleanField fullWidth source="boolean" />,
  [DatapointType.String]: () => <TextField fullWidth source="string" />,
  [DatapointType.Options]: () => (
    <ReferenceField
      label="Datapoint value"
      reference="datapoint_options"
      source="datapoint_option_id"
    >
      <TextField source="value" />
    </ReferenceField>
  ),
  [DatapointType.NumberRange]: () => (
    <>
      <RangeTooltip source="number_start" />
      {" - "}
      <RangeTooltip source="number_end" />
    </>
  ),
  [DatapointType.MoneyRange]: () => (
    <>
      <RangeTooltip source="money_start" />
      {" - "}
      <RangeTooltip source="money_end" />
    </>
  ),
  [DatapointType.DateRange]: () => (
    <>
      <RangeTooltip source="date_start" />
      {" - "}
      <RangeTooltip source="date_end" />
    </>
  ),
  [DatapointType.PensionProvider]: () => (
    <TextField source="pension_provider" />
  ),
};

const NodeAnswerButton = ({
  tooltipRenderer: TooltipRenderer,
}: {
  tooltipRenderer: FC;
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  return (
    <Box marginX="0.2em">
      <Tooltip title={<TooltipRenderer />}>
        <Chip
          deleteIcon={<EditIcon />}
          label={<TextField source="content" />}
          onClick={handleClick}
          onDelete={handleClick}
        />
      </Tooltip>
      <NodeAnswerFormDialog
        setShowDialog={setShowDialog}
        showDialog={showDialog}
      />
    </Box>
  );
};

const ButtonsWrapper = ({ children }: PropsWithChildren) => (
  <Stack flexDirection="row" justifyContent="center">
    {children}
  </Stack>
);

const NodeAnswerButtons = () => {
  const { record } = useShowController<NodeData>();

  if (!record?.datapoint_type) {
    return null;
  }
  const tooltipRenderer = tooltipRenderers[record.datapoint_type];

  const { data: nodeAnswerButtonData } =
    useGetManyReference<NodeAnswerButtonData>(
      "node_answer_buttons",
      {
        id: record?.id,
        pagination: { page: 1, perPage: 100 },
        target: "node",
      },
      { enabled: !!record }
    );
  const nextOrder =
    Math.max(
      ...(nodeAnswerButtonData?.map((button) => button.order ?? 0) ?? [0]),
      0
    ) + 1;

  return (
    <>
      <ReferenceManyField
        label="Node answer buttons"
        reference="node_answer_buttons"
        sort={{ field: "order", order: "ASC" }}
        target="node"
      >
        <SingleFieldList component={ButtonsWrapper} linkType={false}>
          <NodeAnswerButton tooltipRenderer={tooltipRenderer} />
        </SingleFieldList>
      </ReferenceManyField>
      <NodeAnswerButtonFormButton nextOrder={nextOrder} />
    </>
  );
};
export default NodeAnswerButtons;
