import { TransformData } from "ra-core";
import React, { ReactNode } from "react";
import { Create, Edit, Identifier } from "react-admin";

export enum FormType {
  CreateForm,
  EditForm,
}

const CreateOrEdit = ({
  children,
  onSuccess,
  recordId,
  resourceType,
  transform,
  type,
}: {
  children?: ReactNode;
  onSuccess: () => void;
  recordId: Identifier;
  resourceType: string;
  transform: TransformData;
  type: FormType;
}) => {
  const createBoolean = type === FormType.CreateForm;

  if (createBoolean) {
    return (
      <Create
        mutationOptions={{ onSuccess }}
        resource={resourceType}
        transform={transform}
      >
        {children}
      </Create>
    );
  } else {
    return (
      <Edit
        id={recordId}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
        resource={resourceType}
        transform={transform}
      >
        {children}
      </Edit>
    );
  }
};

export default CreateOrEdit;
