import { Box } from "@mui/material";
import React from "react";
import { Identifier, RaRecord, useRecordContext } from "react-admin";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import gfm from "remark-gfm";

interface MarkdownFieldProps {
  display?: string;
  maxHeight?: string;
  record?: RaRecord<Identifier> | Omit<RaRecord<Identifier>, "id">;
  source: string;
}

const MarkdownField = ({
  display,
  maxHeight,
  source,
  record: recordProp,
}: MarkdownFieldProps) => {
  const record = useRecordContext({ recordProp, source });
  const value = record[source]?.toString();

  return (
    <Box
      display={display ?? "inline-block"}
      maxHeight={maxHeight}
      overflow="auto"
      paddingX="1em"
      sx={{
        "& table": { borderCollapse: "collapse" },
        "& th, & td": {
          border: "1px solid",
        },
      }}
    >
      <ReactMarkdown remarkPlugins={[gfm, remarkBreaks]}>{value}</ReactMarkdown>
    </Box>
  );
};

export default MarkdownField;
