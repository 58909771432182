export const csrfToken =
  typeof document === "undefined"
    ? "missing"
    : document.head
        .querySelector("meta[name='csrf-token']")
        ?.getAttribute("content") ?? "missing";

export const headers = () => ({
  "X-CSRF-Token": csrfToken,
});
