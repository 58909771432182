import { Divider, Stack } from "@mui/material";
import React from "react";
import { SimpleForm, TextInput, required } from "react-admin";

import { NodeToolbar } from "./NodeToolbar";

const ContinueNodeForm = () => {
  return (
    <SimpleForm toolbar={<NodeToolbar />}>
      <Stack
        direction="column"
        divider={<Divider flexItem orientation="horizontal" />}
        spacing={1}
        sx={{ width: 1 }}
      >
        <TextInput fullWidth source="continue_button" validate={required()} />
      </Stack>
    </SimpleForm>
  );
};

export default ContinueNodeForm;
