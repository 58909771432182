import jsonapiClient from "ra-jsonapi-client";

import { headers } from "./api";

const settings = {
  headers: headers(),
  total: "total-count",
};

const dataProvider = jsonapiClient("api/v1/admin", settings);

export default dataProvider;
