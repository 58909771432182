import mermaid, { MermaidConfig } from "mermaid";
import React, { useEffect, useRef } from "react";

const DEFAULT_CONFIG = {
  arrowMarkerAbsolute: false,
  flowchart: {
    curve: "linear",
    htmlLabels: true,
  },
  securityLevel: "loose",
  startOnLoad: true,
  theme: "forest",
};

type MermaidProps = {
  chart: string;
  config?: MermaidConfig;
  name?: string;
};

const Mermaid = ({ chart, config }: MermaidProps) => {
  const ref = useRef<HTMLInputElement>(null);

  mermaid.initialize({ ...DEFAULT_CONFIG, ...config });

  useEffect(() => {
    if (ref.current) {
      ref.current.removeAttribute("data-processed");
    }

    mermaid.contentLoaded();
  }, [config, chart]);

  if (!chart) return null;

  return (
    <div className="mermaid" ref={ref}>
      {chart}
    </div>
  );
};

export default Mermaid;
