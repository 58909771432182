import IconContentAdd from "@mui/icons-material/Add";
import IconContentCreate from "@mui/icons-material/Create";
import { ButtonProps } from "@mui/material";
import { TransformData } from "ra-core";
import React, { forwardRef, useState } from "react";
import {
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { EdgeData, NodeData } from "../../../../../types/models";
import CreateOrEdit, { FormType } from "../../../components/CreateOrEdit";
import DialogButton from "../../../components/DialogButton";
import filterDirtyFields from "../../../lib/filterDirtyFields";

import EdgeForm, { Direction, NodeFormType } from "./EdgeForm";

interface EdgeFormButtonProps extends ButtonProps {
  direction: Direction;
  formType: FormType;
  fromNode?: number;
  title: string;
}

// eslint-disable-next-line react/display-name
const EdgeFormButton = forwardRef<HTMLButtonElement, EdgeFormButtonProps>(
  (props, ref) => {
    const { direction, title, formType, ...buttonProps } = props;
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const outBoolean = direction === Direction.Out;
    const createBoolean = formType === FormType.CreateForm;

    const [nodeFormType, setNodeFormType] = useState(NodeFormType.Existing);

    const record = createBoolean
      ? useRecordContext<NodeData>()
      : useRecordContext<EdgeData>();
    const { data: edgesData } = useGetList<EdgeData>(
      "edges",
      {
        filter: { from_node: record.id },
        pagination: { page: 1, perPage: 100 },
      },
      { enabled: createBoolean }
    );
    const nextOrder =
      Math.max(...(edgesData?.map((edge) => edge.order ?? 0) ?? [0]), 0) + 1;

    const onSuccess = () => {
      notify("Changes saved");
      refresh();
      setShowDialog(false);
    };

    const transform: TransformData = (data, options) => {
      const returnData = filterDirtyFields(data, options);

      if (createBoolean) {
        if (outBoolean) {
          returnData["from_node_id"] = record.id;
        } else {
          returnData["to_node_id"] = record.id;
        }
      }
      if (returnData.to_node) {
        returnData.to_node.wizard_id = record.wizard_id;

        if (nodeFormType === NodeFormType.Existing) {
          returnData.to_node = undefined;
        } else if (nodeFormType === NodeFormType.Continue) {
          returnData.to_node.datapoint_id = undefined;
          returnData.to_node_id = undefined;
        } else if (nodeFormType === NodeFormType.Finish) {
          returnData.to_node = undefined;
          returnData.to_node_id = undefined;
        } else {
          returnData.to_node.continue_button = undefined;
          returnData.to_node_id = undefined;
        }
      }

      return returnData;
    };

    return (
      <DialogButton
        buttonProps={buttonProps}
        fullWidth={createBoolean}
        icon={createBoolean ? <IconContentAdd /> : <IconContentCreate />}
        ref={ref}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
        title={title}
      >
        <CreateOrEdit
          recordId={record.id}
          resourceType="edges"
          transform={transform}
          type={formType}
          onSuccess={onSuccess}
        >
          <EdgeForm
            nextOrder={nextOrder}
            nodeFormType={nodeFormType}
            setNodeFormType={setNodeFormType}
            wizardId={record["wizard_id"]}
          />
        </CreateOrEdit>
      </DialogButton>
    );
  }
);

export default EdgeFormButton;
