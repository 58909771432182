import ToastuiEditor from "@toast-ui/editor";
import { PluginFn, WidgetRule } from "@toast-ui/editor/types/editor";
import { useEffect, useMemo, useState } from "react";
import { useGetList } from "react-admin";

import { DatapointData } from "../../../types/models";

const datapointInterpolationRule = /{{(\S+)=(\S+)}}/;

const useDatapointInterpolationPlugin = (
  editor?: ToastuiEditor
): [PluginFn, WidgetRule | false, boolean, () => void] => {
  const [showDialog, setShowDialog] = useState(false);
  const handleClose = () => setShowDialog(false);

  useEffect(() => {
    if (editor) {
      const command = () => {
        setShowDialog(true);

        return true;
      };
      editor.addCommand("markdown", "datapointInterpolationDialog", command);
      editor.addCommand("wysiwyg", "datapointInterpolationDialog", command);
    }
  }, [editor]);

  const { data: datapoints } = useGetList<DatapointData>("datapoints", {
    pagination: { page: 1, perPage: 500 },
  });

  const hasData = !!datapoints;
  const datapointInterpolationWidget = hasData && {
    rule: datapointInterpolationRule,
    toDOM: (text: string) => {
      const matched = text.match(datapointInterpolationRule);
      const span = document.createElement("span");
      span.style.border = "1px solid lightgray";
      span.style.borderRadius = "8px";
      span.style.padding = "0 4px";

      if (matched) {
        const [_, datapointId, format] = matched;
        span.innerHTML =
          datapoints.find(
            (datapoint) => datapoint.id.toString() === datapointId
          )?.slug ?? "missing datapoint";
        span.innerHTML += ` (${format})`;
      }

      return span;
    },
  };

  const datapointInterpolationPlugin = useMemo<PluginFn>(
    () => () => ({
      toolbarItems: [
        {
          groupIndex: 0,
          item: {
            className: "toastui-editor-toolbar-icons",
            command: "datapointInterpolationDialog",
            name: "datapoint",
            style: { backgroundImage: "none", color: "gray", fontSize: "18px" },
            text: "[x]",
            tooltip: "Datapoint",
          },
          itemIndex: 0,
        },
      ],
    }),
    []
  );

  return [
    datapointInterpolationPlugin,
    datapointInterpolationWidget,
    showDialog,
    handleClose,
  ];
};

export default useDatapointInterpolationPlugin;
