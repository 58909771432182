import { Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetManyReference } from "react-admin";
import { useWatch } from "react-hook-form";

import { DatapointOptionData } from "../../../../../types/models";
import { prefixedString } from "../../../lib/strings";

export const DatapointDetails = ({ prefix }: { prefix?: string }) => {
  const datapointId = useWatch({
    name: prefixedString("datapoint_id", prefix),
  });

  const { data: datapointOptionData } =
    useGetManyReference<DatapointOptionData>(
      "datapoint_options",
      {
        id: datapointId,
        sort: { field: "value", order: "ASC" },
        target: "datapoint_id",
      },
      { enabled: !!datapointId }
    );

  if (!datapointOptionData || datapointOptionData.length === 0) return null;

  return (
    <Stack direction="row" spacing={2}>
      <Typography>Datapoint options:</Typography>
      {datapointOptionData.map((datapointOption) => (
        <Chip key={datapointOption.id} label={datapointOption.value} />
      ))}
    </Stack>
  );
};
