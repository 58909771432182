import React from "react";
import {
  ArrayInput,
  DateInput,
  DeleteButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  ToolbarClasses,
  ToolbarProps,
  required,
} from "react-admin";

import MoneyInput from "../../../components/MoneyInput";
import { VariableType } from "../../variables/lib/types";

const VariableValueToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" redirect={false} />
    </div>
  </Toolbar>
);

const ValueInput = ({ variable_type }: { variable_type: VariableType }) => {
  switch (variable_type) {
    case VariableType.Date:
      return (
        <DateInput
          fullWidth
          label="date"
          name="date"
          source="date"
          validate={required()}
        />
      );

    case VariableType.DateArray:
      return (
        <ArrayInput source="date_array">
          <SimpleFormIterator>
            <DateInput
              fullWidth
              label="Date"
              source="."
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      );

    case VariableType.MoneyArray:
      return (
        <ArrayInput source="number_array">
          <SimpleFormIterator>
            <MoneyInput fullWidth source="." validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      );

    case VariableType.Money:
      return <MoneyInput fullWidth source="number" validate={required()} />;

    case VariableType.NumberArray:
      return (
        <ArrayInput source="number_array">
          <SimpleFormIterator>
            <NumberInput
              fullWidth
              label="Number"
              source="."
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      );

    default:
      return (
        <NumberInput
          fullWidth
          name="number"
          source="number"
          validate={required()}
        />
      );
  }
};

const VariableValueForm = ({
  variable_type,
}: {
  variable_type: VariableType;
}) => (
  <SimpleForm toolbar={<VariableValueToolbar />}>
    <ValueInput variable_type={variable_type} />
    <DateInput source="start_date" />
    <DateInput source="end_date" />
  </SimpleForm>
);

export default VariableValueForm;
