import React from "react";
import { BooleanField, Datagrid, ReferenceField, TextField } from "react-admin";

const NodeDatagrid = () => (
  <Datagrid bulkActionButtons={false} rowClick="show">
    <TextField source="id" />
    <TextField source="slug" />
    <BooleanField label="Valid" source="valid_for_wizard?" />
    <TextField source="question" />
    <ReferenceField link="show" reference="datapoints" source="datapoint_id">
      <TextField source="slug" />
    </ReferenceField>
  </Datagrid>
);

export default NodeDatagrid;
