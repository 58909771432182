import IconContentAdd from "@mui/icons-material/Add";
import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { ClassifierSampleData } from "../../../../../types/models";
import DialogButton from "../../../components/DialogButton";
import filterDirtyFields from "../../../lib/filterDirtyFields";

const ClassifierSampleBulkFormButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<ClassifierSampleData>();

  const title = "Create classifier samples in bulk";

  const onSuccess = () => {
    notify("Changes saved");
    refresh();
    setShowDialog(false);
  };

  return (
    <DialogButton
      fullWidth
      buttonProps={{ size: "small" }}
      icon={<IconContentAdd />}
      setShowDialog={setShowDialog}
      showDialog={showDialog}
      title={title}
    >
      <Edit
        actions={<></>}
        id={record.id}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
        resource="wizards"
        transform={filterDirtyFields}
      >
        <SimpleForm>
          <TextInput
            fullWidth
            multiline
            helperText="You can enter multiple samples. Each line will become a new sample"
            source="bulk_samples"
            validate={required()}
          />
        </SimpleForm>
      </Edit>
    </DialogButton>
  );
};

export default ClassifierSampleBulkFormButton;
