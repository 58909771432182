import { Box, Typography } from "@mui/material";
import React from "react";
import { LinearProgress, LinearProgressProps } from "react-admin";

export const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Box sx={{ mr: 1, width: "100%" }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography color="text.secondary" variant="body2">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
