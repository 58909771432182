import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  TextField,
} from "react-admin";

const ClassifierDatagrid = () => (
  <Datagrid bulkActionButtons={false} rowClick="edit">
    <TextField source="classifier_type" />
    <BooleanField source="active" />
    <NumberField source="id" />

    <DateField showTime={true} source="created_at" />
    <DateField showTime={true} source="updated_at" />
  </Datagrid>
);

export default ClassifierDatagrid;
