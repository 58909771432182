import { Dialog, DialogTitle } from "@mui/material";
import React from "react";
import {
  Create,
  DateInput,
  DeleteButton,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarClasses,
  ToolbarProps,
  TransformData,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import { NodeAnswerButtonData } from "../../../../../types/models";
import MoneyInput from "../../../components/MoneyInput";
import filterDirtyFields from "../../../lib/filterDirtyFields";
import {
  DatapointType,
  pensionProviderChoices,
} from "../../datapoints/lib/types";
import BooleanRenderer from "../field_renderers/Boolean";
import RangeRenderer from "../field_renderers/Range";

const NodeAnswerButtonToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" redirect={false} />
    </div>
  </Toolbar>
);

const fieldRenderers: {
  [datapointType in DatapointType]: ({
    datapointId,
  }: {
    datapointId: number;
  }) => JSX.Element;
} = {
  [DatapointType.Boolean]: BooleanRenderer,
  [DatapointType.String]: () => (
    <TextInput fullWidth source="string" validate={required()} />
  ),
  [DatapointType.Options]: ({ datapointId }) => (
    <ReferenceInput
      filter={{ datapoint: datapointId }}
      reference="datapoint_options"
      source="datapoint_option_id"
    >
      <SelectInput
        fullWidth
        label="Datapoint options"
        optionText="value"
        validate={required()}
      />
    </ReferenceInput>
  ),
  [DatapointType.NumberRange]: () => (
    <RangeRenderer attribute="number" input={NumberInput} />
  ),
  [DatapointType.MoneyRange]: () => (
    <RangeRenderer attribute="money" input={MoneyInput} />
  ),
  [DatapointType.DateRange]: () => (
    <RangeRenderer attribute="date" input={DateInput} />
  ),
  [DatapointType.PensionProvider]: () => (
    <SelectInput
      fullWidth
      choices={pensionProviderChoices}
      source="pension_provider"
      validate={required()}
    />
  ),
};

const NodeAnswerButtonForm = ({
  datapointId,
  datapointType,
  nextOrder,
}: {
  datapointId: number;
  datapointType: DatapointType;
  nextOrder?: number;
}) => {
  const FieldRenderer = fieldRenderers[datapointType];

  return (
    <SimpleForm
      defaultValues={{
        date_end_include: true,
        date_start_include: true,
        number_end_include: true,
        number_start_include: true,
        order: nextOrder,
      }}
      toolbar={<NodeAnswerButtonToolbar />}
    >
      <NumberInput fullWidth source="order" validate={required()} />
      <TextInput fullWidth source="content" validate={required()} />
      <FieldRenderer datapointId={datapointId} />
    </SimpleForm>
  );
};

const NodeAnswerFormDialog = ({
  createBoolean,
  nextOrder,
  setShowDialog,
  showDialog,
}: {
  createBoolean?: boolean;
  nextOrder?: number;
  setShowDialog: (show: boolean) => void;
  showDialog: boolean;
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<NodeAnswerButtonData>();
  const labelButton = `${createBoolean ? "Create" : "Edit"} node answer button`;
  const datapointType = record.datapoint_type;

  if (!record.datapoint_id || !datapointType) {
    return null;
  }

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const onSuccess = () => {
    notify("Changes saved");
    refresh();
    setShowDialog(false);
  };

  const transform: TransformData = (data, options) => {
    const filteredData = filterDirtyFields(data, options);

    if (createBoolean) {
      filteredData["node_id"] = record.id;
    }

    return filteredData;
  };

  return (
    <Dialog
      fullWidth
      aria-label={labelButton}
      open={showDialog}
      onClose={handleCloseClick}
    >
      <DialogTitle>{labelButton}</DialogTitle>
      {createBoolean ? (
        <Create
          mutationOptions={{ onSuccess }}
          resource="node_answer_buttons"
          transform={transform}
        >
          <NodeAnswerButtonForm
            datapointId={record.datapoint_id}
            datapointType={datapointType}
            nextOrder={nextOrder}
          />
        </Create>
      ) : (
        <Edit
          id={record.id}
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess }}
          resource="node_answer_buttons"
          transform={transform}
        >
          <NodeAnswerButtonForm
            datapointId={record.datapoint_id}
            datapointType={datapointType}
          />
        </Edit>
      )}
    </Dialog>
  );
};

export default NodeAnswerFormDialog;
