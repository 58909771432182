import React from "react";
import { Datagrid, DeleteButton, TextField } from "react-admin";

import { FormType } from "../../../components/CreateOrEdit";

import ClassifierSampleFormButton from "./ClassifierSampleFormButton";

const ClassifierSamplesButtonsField = () => (
  <span>
    <ClassifierSampleFormButton formType={FormType.EditForm} />
    <DeleteButton mutationMode="pessimistic" redirect={false} />
  </span>
);
const ClassifierSamplesDatagrid = () => (
  <Datagrid bulkActionButtons={false} rowClick={false}>
    <TextField source="id" />
    <TextField source="text" />
    <ClassifierSamplesButtonsField />
  </Datagrid>
);

export default ClassifierSamplesDatagrid;
