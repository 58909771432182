export enum DatapointType {
  String = "string",
  NumberRange = "number_range",
  MoneyRange = "money_range",
  DateRange = "date_range",
  Options = "options",
  Boolean = "boolean",
  PensionProvider = "pension_provider",
}

export enum ConditionType {
  Datapoint = "datapoint",
  Variable = "variable",
  Date = "date",
}

export const datapointTypeChoices = (
  Object.keys(DatapointType) as (keyof typeof DatapointType)[]
).map((key) => ({
  id: DatapointType[key],
  name: DatapointType[key],
}));

export enum PensionProvider {
  ABP = "abp",
  BPF = "bpf",
  BPL = "bpl",
  PA = "pa",
  PD = "pd",
  PHC = "phc",
  PME = "pme",
  PMT = "pmt",
  PSG = "psg",
  PZW = "pzw",
  SPW = "spw",
  Stipp = "stipp",
  Other = "other",
  Unknown = "unknown",
}

export const pensionProviderChoices = (
  Object.keys(PensionProvider) as (keyof typeof PensionProvider)[]
).map((key) => ({
  id: PensionProvider[key],
  name: PensionProvider[key],
}));
