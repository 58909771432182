import VisibilityEye from "@mui/icons-material/Visibility";
import { Box, Button, Card, CardContent, Stack, Tooltip } from "@mui/material";
import React, { PropsWithChildren } from "react";
import {
  ArrayField,
  ChipField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";

import { FormType } from "../../../components/CreateOrEdit";
import MarkdownField from "../../../components/MarkdownField";
import { Direction } from "../../edges/components/EdgeForm";
import EdgeFormButton from "../../edges/components/EdgeFormButton";

const RelatedWrapper = ({ children }: PropsWithChildren) => (
  <Stack direction="row" justifyContent="space-around" marginTop="0!important">
    {children}
  </Stack>
);

const RelatedNodeArrow = ({ direction }: { direction: Direction }) => {
  const outBoolean = direction === Direction.Out;
  const record = useRecordContext();

  return (
    <Box
      sx={{
        "&:after": {
          border: "none",
          borderLeft: "8px solid rgba(0,0,0,0)",
          borderRight: "8px solid rgba(0,0,0,0)",
          borderTop: "8px solid #000000",
          content: "''",
          height: "0",
          position: "absolute",
          top: "100%",
          transform: "translateX(-50%)",
          width: "0",
        },
        backgroundColor: "black",
        display: "inline-block",
        flexGrow: 1,
        margin: "0px",
        position: "relative",
        width: "2px",
      }}
    >
      <ArrayField label="Conditions" source="conditions">
        <SingleFieldList
          component={Box}
          linkType={false}
          {...({ display: "inline-block" } as any)}
        >
          <ChipField source="condition_string" />
        </SingleFieldList>
      </ArrayField>
      <Tooltip
        title={
          <Card sx={{ width: "26em" }}>
            {record["reply_preview"] ? (
              <MarkdownField
                display="block"
                maxHeight="20em"
                source="reply_preview"
              />
            ) : (
              <Box padding="1em">No reply</Box>
            )}
          </Card>
        }
      >
        {outBoolean ? (
          <EdgeFormButton
            direction={direction}
            formType={FormType.EditForm}
            title="Edit"
          />
        ) : (
          <Button startIcon={<VisibilityEye />}>Reply</Button>
        )}
      </Tooltip>
    </Box>
  );
};

const RelatedNode = ({ direction }: { direction: Direction }) => {
  const outBoolean = direction === Direction.Out;
  const source = outBoolean ? "to_node_id" : "from_node_id";
  const record = useRecordContext();
  const id = record[source];

  return (
    <Stack alignItems="center" position="relative">
      {outBoolean && <RelatedNodeArrow direction={direction} />}
      <Card sx={{ marginTop: "0.5em" }} variant="outlined">
        <CardContent>
          {id ? (
            <ReferenceField
              key="slug"
              link="show"
              reference="nodes"
              source={source}
            >
              <TextField source="slug" />
              <TextField source="continue_button" />
            </ReferenceField>
          ) : (
            "Finish"
          )}
        </CardContent>
      </Card>
      {!outBoolean && <RelatedNodeArrow direction={direction} />}
    </Stack>
  );
};

const RelatedNodes = ({ direction }: { direction: Direction }) => {
  const outBoolean = direction === Direction.Out;
  const target = outBoolean ? "from_node" : "to_node";

  return (
    <ReferenceManyField
      label={false}
      reference="edges"
      sort={{ field: "order", order: "ASC" }}
      target={target}
    >
      <SingleFieldList component={RelatedWrapper} linkType={false}>
        <RelatedNode direction={direction} />
      </SingleFieldList>
    </ReferenceManyField>
  );
};

export default RelatedNodes;
