import IconContentAdd from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-admin";

import NodeAnswerFormDialog from "./NodeAnswerFormDialog";

const NodeAnswerButtonFormButton = ({ nextOrder }: { nextOrder?: number }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  return (
    <Box marginY="1em">
      <Button fullWidth label="Add button" onClick={handleClick}>
        <IconContentAdd />
      </Button>
      <NodeAnswerFormDialog
        createBoolean
        nextOrder={nextOrder}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
      />
    </Box>
  );
};

export default NodeAnswerButtonFormButton;
