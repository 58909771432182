import React from "react";
import { List } from "react-admin";

import CalculationDatagrid from "./components/CalculationDatagrid";

const CalculationList = () => (
  <List perPage={100}>
    <CalculationDatagrid rowClick="edit" />
  </List>
);

export default CalculationList;
