import IconContentAdd from "@mui/icons-material/Add";
import IconContentCreate from "@mui/icons-material/Create";
import { TransformData } from "ra-core";
import React, { useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";

import { ClassifierSampleData } from "../../../../../types/models";
import CreateOrEdit, { FormType } from "../../../components/CreateOrEdit";
import DialogButton from "../../../components/DialogButton";
import filterDirtyFields from "../../../lib/filterDirtyFields";

import ClassifierSampleForm from "./ClassifierSampleForm";

const ClassifierSampleFormButton = ({
  defaultValues,
  formType,
  showWizardSelect,
  wizard_id,
}: {
  defaultValues?: { text: string; wizard_id: number };
  formType: FormType;
  showWizardSelect?: boolean;
  wizard_id?: number;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<ClassifierSampleData>();

  const createBoolean = formType === FormType.CreateForm;
  const title = `${createBoolean ? "Create" : "Edit"} classifier sample `;

  const onSuccess = () => {
    notify("Changes saved");
    refresh();
    setShowDialog(false);
  };

  const transform: TransformData = (data, options) => {
    const returnData = filterDirtyFields(data, options);

    if (createBoolean && !returnData["wizard_id"]) {
      returnData["wizard_id"] = wizard_id || record.id;
    }

    return returnData;
  };

  return (
    <DialogButton
      buttonProps={{ size: "small" }}
      fullWidth={createBoolean}
      icon={createBoolean ? <IconContentAdd /> : <IconContentCreate />}
      setShowDialog={setShowDialog}
      showDialog={showDialog}
      title={title}
    >
      <CreateOrEdit
        recordId={record.id}
        resourceType="classifier_samples"
        transform={transform}
        type={formType}
        onSuccess={onSuccess}
      >
        <ClassifierSampleForm
          defaultValues={defaultValues}
          showWizardSelect={showWizardSelect}
        />
      </CreateOrEdit>
    </DialogButton>
  );
};

export default ClassifierSampleFormButton;
