import { Chip } from "@mui/material";
import React from "react";
import {
  ArrayField,
  Datagrid,
  FunctionField,
  SingleFieldList,
} from "react-admin";

import { CalculationParameterData } from "../../../../../types/models";
import calculationName from "../lib/calculationName";

const ParameterChip = ({
  parameter,
}: {
  parameter: CalculationParameterData;
}) => {
  const valid =
    !!parameter.datapoint_id &&
    !parameter.calculation_datapoint_options_attributes?.find(
      (option) => !option.datapoint_option_id
    );

  return (
    <Chip
      color={valid ? "success" : "warning"}
      label={`${parameter.parameter_name}: ${parameter.type}`}
      sx={{ margin: 0.5 }}
    />
  );
};

const ParameterArrayField = ({ source }: { source: string }) => (
  <ArrayField source={source}>
    <SingleFieldList linkType={false}>
      <FunctionField
        label="Name"
        render={(parameter: CalculationParameterData) => (
          <ParameterChip parameter={parameter} />
        )}
      />
    </SingleFieldList>
  </ArrayField>
);

const CalculationDatagrid = ({
  rowClick,
}: {
  rowClick: string | false | undefined;
}) => (
  <Datagrid bulkActionButtons={false} rowClick={rowClick} size="medium">
    <FunctionField render={calculationName} source="type_name" />
    <ParameterArrayField source="input_parameters" />
    <ParameterArrayField source="output_parameters" />
  </Datagrid>
);

export default CalculationDatagrid;
