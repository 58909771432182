import { Chip } from "@mui/material";
import React from "react";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  TextField,
} from "react-admin";

import { WizardData } from "../../../../types/models";

const WizardList = () => (
  <List perPage={500} sort={{ field: "id", order: "ASC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <BooleanField source="published" />
      <TextField source="id" />
      <TextField source="question" />
      <NumberField label="Journeys" locales="nl-NL" source="journeys_count" />
      <NumberField
        label="Finished"
        locales="nl-NL"
        source="finished_journeys_count"
      />
      <NumberField
        label="Helpful"
        locales="nl-NL"
        source="helpful_journeys_count"
      />
      <NumberField
        label="Unhelpful"
        locales="nl-NL"
        source="unhelpful_journeys_count"
      />
      <FunctionField
        render={(wizard: WizardData) =>
          wizard.tag_list.map((tag) => <Chip key={tag} label={tag} />)
        }
        source="tag_list"
      />
    </Datagrid>
  </List>
);

export default WizardList;
