import React from "react";
import { Edit, useRecordContext } from "react-admin";

import filterDirtyFields from "../../lib/filterDirtyFields";

import ContinueNodeForm from "./components/ContinueNodeForm";
import NodeForm from "./components/NodeForm";

const Form = () => {
  const record = useRecordContext();

  return record?.continue_button ? <ContinueNodeForm /> : <NodeForm />;
};

const NodeEdit = () => {
  return (
    <Edit
      mutationMode="pessimistic"
      redirect="show"
      transform={filterDirtyFields}
    >
      <Form />
    </Edit>
  );
};

export default NodeEdit;
