import DownloadIcon from "@mui/icons-material/Download";
import { Link, Stack } from "@mui/material";
import React from "react";
import { List } from "react-admin";

import ClassifierDatagrid from "./components/ClassifierDataGrid";
import ClassifierWizardList from "./components/ClassifierWizardList";

const ClassifierList = () => {
  return (
    <Stack>
      <List
        exporter={false}
        perPage={100}
        sort={{ field: "created_at", order: "ASC" }}
      >
        <ClassifierDatagrid />
      </List>
      <Link
        download
        alignItems="center"
        display="flex"
        fontFamily={'"Roboto","Helvetica","Arial",sans-serif'}
        fontSize="0.875rem"
        href="/api/v1/admin/classifier_samples/export_data"
        textTransform="uppercase"
      >
        <DownloadIcon />
        Export training data
      </Link>
      <ClassifierWizardList />
    </Stack>
  );
};

export default ClassifierList;
