import React from "react";
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  ToolbarClasses,
  ToolbarProps,
  useRecordContext,
} from "react-admin";

import { NodeData } from "../../../../../types/models";

export const NodeToolbar = (props: ToolbarProps) => {
  const record = useRecordContext<NodeData>();

  return (
    <Toolbar {...props}>
      <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
        {!record?.["first_node?"] && <DeleteButton />}
      </div>
    </Toolbar>
  );
};
