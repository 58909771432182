import IconContentAdd from "@mui/icons-material/Add";
import IconCheck from "@mui/icons-material/Check";
import IconContentCopy from "@mui/icons-material/ContentCopy";
import IconExternal from "@mui/icons-material/OpenInNew";
import {
  Chip,
  Divider,
  Button as MUIButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  BooleanField,
  Button,
  FunctionField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRedirect,
  useShowController,
} from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { WizardData } from "../../../../types/models";
import ClassifierSampleBulkFormButton from "../classifier_samples/components/ClassifierSampleBulkFormButton";
import ClassifierSampleDatagrid from "../classifier_samples/components/ClassifierSamplesDatagrid";
import NodeDatagrid from "../nodes/components/NodeDatagrid";
import NodeEdgeGraph from "../nodes/components/NodeEdgeGraph";

const COPY_DURATION = 1000;

const CopyGPTPrompt = ({ question }: { question: string }) => {
  const [copied, setCopied] = useState(false);
  const prompt =
    `Genereer 50 positieve voorbeelden voor fasttext vraagherkenning voor de vraag "${question}". ` +
    "Schrijf deze voorbeelden gedeeltelijk in een hele simpele taal, alsof het door laaggeletterden wordt gebruikt. " +
    "Graag verschillende woorden gebruiken voor de hoofdterm. Gebruik geen quotes.";

  return (
    <CopyToClipboard
      text={prompt}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), COPY_DURATION);
      }}
    >
      <MUIButton startIcon={copied ? <IconCheck /> : <IconContentCopy />}>
        {copied ? "Copied" : "Copy GPT prompt"}
      </MUIButton>
    </CopyToClipboard>
  );
};
const CreateNodeButton = () => {
  const record = useRecordContext<WizardData>();
  const redirect = useRedirect();

  if (!record) return null;

  const onClick = () => {
    redirect(
      "create",
      "nodes",
      undefined,
      {},
      { record: { wizard_id: record.id } }
    );
  };

  return (
    <Button
      label="Create a new node"
      startIcon={<IconContentAdd />}
      title="Create a new node"
      onClick={onClick}
    />
  );
};

const WizardShow = () => {
  const { record } = useShowController<WizardData>();

  if (!record) return null;

  return (
    <Show>
      <Stack
        direction="column"
        divider={<Divider flexItem orientation="horizontal" />}
        spacing={1}
      >
        <SimpleShowLayout>
          <Typography gutterBottom variant="h6">
            Basic information
          </Typography>

          <BooleanField source="published" />
          <TextField source="id" />
          <TextField source="question" />
          <FunctionField
            render={(wizard: WizardData) =>
              wizard.tag_list.map((tag) => <Chip key={tag} label={tag} />)
            }
            source="tag_list"
          />
          <MUIButton
            component="a"
            href={`${window.STM_testRoot}?id=${record.id}&test-mode=true`}
            startIcon={<IconExternal />}
            target="_blank"
          >
            Test
          </MUIButton>
        </SimpleShowLayout>
        <SimpleShowLayout>
          <Typography gutterBottom variant="h6">
            Nodes
          </Typography>
          <NodeEdgeGraph />
          <ReferenceManyField
            label="Nodes"
            reference="nodes"
            sort={{ field: "question", order: "ASC" }}
            target="wizard"
          >
            <NodeDatagrid />
          </ReferenceManyField>
          <CreateNodeButton />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <Typography gutterBottom variant="h6">
            Classifier samples
          </Typography>
          <CopyGPTPrompt question={record.question} />
          <ReferenceManyField
            label="Classifier samples"
            reference="classifier_samples"
            target="wizard"
          >
            <ClassifierSampleDatagrid />
          </ReferenceManyField>
          <ClassifierSampleBulkFormButton />
        </SimpleShowLayout>
      </Stack>
    </Show>
  );
};

export default WizardShow;
