import { TransformData } from "ra-core";

const filterDirtyFields: TransformData = (data, options) => {
  if (!options) {
    return data;
  }

  const { previousData } = options;

  const returnData = { ...data };

  Object.keys(data).forEach((key) => {
    if (data[key] === previousData[key]) {
      delete returnData[key];
    }
  });

  return returnData;
};

export default filterDirtyFields;
