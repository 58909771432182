import React from "react";
import { SelectInput, required, useGetOne } from "react-admin";
import { useWatch } from "react-hook-form";

import { DatapointData } from "../../../../../types/models";
import { prefixedString } from "../../../lib/strings";
import { DatapointType } from "../../datapoints/lib/types";

export enum NodeAnswerType {
  String = "string",
  Number = "number",
  Money = "money",
  Date = "date",
  Buttons = "buttons",
  PensionProvider = "pension_provider",
}

const nodeAnswerTypesSelectValues = {
  [NodeAnswerType.String]: { id: "string", name: "string" },
  [NodeAnswerType.Number]: { id: "number", name: "number" },
  [NodeAnswerType.Money]: { id: "money", name: "money" },
  [NodeAnswerType.Date]: { id: "date", name: "date" },
  [NodeAnswerType.Buttons]: { id: "buttons", name: "buttons" },
  [NodeAnswerType.PensionProvider]: {
    id: "pension_provider",
    name: "pension_provider",
  },
};

const nodeAnswerTypeToSelectValues = (types: NodeAnswerType[]) =>
  types.map((type) => nodeAnswerTypesSelectValues[type]);

const nodeAnswerTypeInputOptions: {
  [datapointType in DatapointType | ""]: any;
} = {
  [""]: null,
  [DatapointType.Boolean]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.Buttons,
  ]),
  [DatapointType.String]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.Buttons,
    NodeAnswerType.String,
  ]),
  [DatapointType.Options]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.Buttons,
  ]),
  [DatapointType.NumberRange]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.Buttons,
    NodeAnswerType.Number,
  ]),
  [DatapointType.MoneyRange]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.Buttons,
    NodeAnswerType.Money,
  ]),
  [DatapointType.DateRange]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.Buttons,
    NodeAnswerType.Date,
  ]),
  [DatapointType.PensionProvider]: nodeAnswerTypeToSelectValues([
    NodeAnswerType.PensionProvider,
  ]),
};
export const NodeAnswerTypeInput = ({ prefix }: { prefix?: string }) => {
  const datapointId = useWatch({
    name: prefixedString("datapoint_id", prefix),
  });

  const { data } = useGetOne<DatapointData>(
    "datapoints",
    {
      id: datapointId,
    },
    { enabled: !!datapointId }
  );

  if (!data) return null;

  return (
    <SelectInput
      choices={nodeAnswerTypeInputOptions[data.datapoint_type ?? ""]}
      source={prefixedString("answer_type", prefix)}
      validate={required()}
    />
  );
};
