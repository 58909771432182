import { Typography } from "@mui/material";
import React from "react";
import { Create, SelectInput, SimpleForm, required } from "react-admin";

import DirectUploadFileInput from "../../components/DirectUploadFileInput";

const ClassifierCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <Typography variant="h6">Create new classifier </Typography>
        <Typography>
          Upload a ftz (model) file here. This file can be created a developer.
        </Typography>
        <DirectUploadFileInput
          accept=".ftz"
          label="Model file"
          source="model_file"
        />
        <SelectInput
          choices={[
            { id: "question", name: "question" },
            { id: "category", name: "category" },
          ]}
          label="classifier_type"
          source="classifier_type"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default ClassifierCreate;
