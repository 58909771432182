import { Box, Button, ButtonGroup, Stack } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteButton,
  FormDataConsumer,
  Labeled,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  ToolbarClasses,
  ToolbarProps,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";

import { DatapointData, EdgeData } from "../../../../../types/models";
import MarkdownInput from "../../../components/MarkdownInput";
import MoneyInput from "../../../components/MoneyInput";
import OffsetInput from "../../../components/OffsetInput";
import DatapointCreateButton from "../../datapoints/components/DatapointCreateButton";
import {
  datapointOptionSelectRenderer,
  datapointSelectRenderer,
  variableSelectRenderer,
} from "../../datapoints/lib/renderers";
import {
  ConditionType,
  DatapointType,
  pensionProviderChoices,
} from "../../datapoints/lib/types";
import { DatapointDetails } from "../../nodes/components/DatapointDetails";
import { NodeAnswerTypeInput } from "../../nodes/components/NodeAnswerTypeInput";
import { nodeSelectRenderer } from "../../nodes/lib/renderers";

export enum Direction {
  In,
  Out,
}

export enum Operator {
  Equals = "equals",
  GreaterThanOrEqual = "greater_than_or_equal",
  LessThanOrEqual = "less_than_or_equal",
}

const EdgeToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton alwaysEnable />
      <DeleteButton mutationMode="pessimistic" redirect={false} />
    </div>
  </Toolbar>
);

const operatorChoices = (
  Object.keys(Operator) as (keyof typeof Operator)[]
).map((key) => ({
  id: Operator[key],
  name: Operator[key],
}));

const conditionValueInputRenderers: {
  [datapointType in DatapointType | ""]: (
    getSource: (name: string) => string,
    datapoint_id: number
  ) => ReactNode;
} = {
  [""]: () => null,
  [DatapointType.Boolean]: (getSource) => (
    <RadioButtonGroupInput
      choices={[
        { id: true, name: "true" },
        { id: false, name: "false" },
        { id: "", name: "unknown" },
      ]}
      source={getSource("boolean")}
    />
  ),
  [DatapointType.String]: (getSource) => (
    <TextInput source={getSource("string")} />
  ),
  [DatapointType.Options]: (getSource, datapoint_id) => (
    <ReferenceInput
      filter={{ datapoint: datapoint_id }}
      reference="datapoint_options"
      sort={{ field: "value", order: "ASC" }}
      source={getSource("datapoint_option_id")}
    >
      <AutocompleteInput
        label="Datapoint option"
        optionText={datapointOptionSelectRenderer}
      />
    </ReferenceInput>
  ),
  [DatapointType.NumberRange]: (getSource) => (
    <NumberInput source={getSource("number")} />
  ),
  [DatapointType.MoneyRange]: (getSource) => (
    <MoneyInput source={getSource("number")} />
  ),
  [DatapointType.DateRange]: (getSource) => (
    <DateInput source={getSource("date")} />
  ),
  [DatapointType.PensionProvider]: (getSource) => (
    <SelectInput
      choices={pensionProviderChoices}
      source={getSource("pension_provider")}
    />
  ),
};

interface OperatorInputOptions {
  choices: Array<{
    id: string;
    name: string;
  }>;
  disabled?: boolean;
  validate?: (value: any, values: any) => any;
}

const operatorInputOptions: {
  [datapointType in DatapointType | ""]: OperatorInputOptions;
} = {
  [""]: {
    choices: operatorChoices,
    disabled: true,
  },
  [DatapointType.Boolean]: {
    choices: [{ id: "equals", name: "equals" }],
    validate: required(),
  },
  [DatapointType.String]: {
    choices: [{ id: "equals", name: "equals" }],
    validate: required(),
  },
  [DatapointType.Options]: {
    choices: [{ id: "equals", name: "equals" }],
    validate: required(),
  },
  [DatapointType.NumberRange]: {
    choices: operatorChoices,
    validate: required(),
  },
  [DatapointType.MoneyRange]: {
    choices: operatorChoices,
    validate: required(),
  },
  [DatapointType.DateRange]: {
    choices: operatorChoices,
    validate: required(),
  },
  [DatapointType.PensionProvider]: {
    choices: [{ id: "equals", name: "equals" }],
    validate: required(),
  },
};

const ExistingNodeForm = ({ wizardId }: { wizardId: number }) => (
  <ReferenceInput
    fullWidth
    filter={{ wizard: wizardId }}
    key="nodes"
    reference="nodes"
    sort={{ field: "slug", order: "ASC" }}
    source="to_node_id"
  >
    <AutocompleteInput
      fullWidth
      label="Node"
      optionText={nodeSelectRenderer}
      validate={required()}
    />
  </ReferenceInput>
);

const NewNodeForm = () => (
  <>
    <ReferenceInput
      key="datapoints"
      reference="datapoints"
      sort={{ field: "slug", order: "ASC" }}
      source="to_node.datapoint_id"
    >
      <AutocompleteInput
        fullWidth
        label="Datapoint"
        optionText="slug"
        validate={required()}
      />
    </ReferenceInput>
    <DatapointCreateButton prefix="to_node" />
    <DatapointDetails prefix="to_node" />
    <NodeAnswerTypeInput prefix="to_node" />
    <TextInput fullWidth source="to_node.slug" validate={required()} />
    <MarkdownInput
      interpolation
      source="to_node.question"
      validate={required()}
    />
  </>
);

const ContinueForm = () => {
  return (
    <TextInput
      fullWidth
      label="Button"
      source="to_node.continue_button"
      validate={required()}
    />
  );
};

export enum NodeFormType {
  Existing,
  New,
  Continue,
  Finish,
}

const renderNodeForm = (nodeFormType: NodeFormType, wizardId: number) => {
  switch (nodeFormType) {
    case NodeFormType.Existing:
      return <ExistingNodeForm wizardId={wizardId} />;
    case NodeFormType.New:
      return <NewNodeForm />;
    case NodeFormType.Continue:
      return <ContinueForm />;
    case NodeFormType.Finish:
      return null;
  }
};

const compareWithVariable = (
  getSource: (name: string) => string,
  datapointType?: string
) => {
  return (
    <Stack direction="row" spacing={1}>
      <ReferenceInput
        filter={{ variable_type: datapointType }}
        reference="variables"
        sort={{ field: "name", order: "ASC" }}
        source={getSource("compare_variable_attributes.variable_id")}
        validate={required()}
      >
        <AutocompleteInput
          label={false}
          optionText={variableSelectRenderer}
          validate={required()}
        />
      </ReferenceInput>
      <OffsetInput prefix={getSource("compare_variable_attributes")} />
    </Stack>
  );
};

const initialNodeFormType = (edge: EdgeData) => {
  if (edge && !edge.to_node_id) {
    return NodeFormType.Finish;
  }

  return NodeFormType.Existing;
};

const ConditionDatapointForm = ({
  datapointId,
  getSource,
}: {
  datapointId: number;
  getSource: (source: string) => string;
}) => {
  const { data } = useGetOne<DatapointData>(
    "datapoints",
    {
      id: datapointId,
    },
    { enabled: !!datapointId }
  );
  const conditionValueInputRenderer =
    conditionValueInputRenderers[data?.datapoint_type ?? ""];
  const compareVariable = useWatch({
    name: getSource("variable_comparison"),
  });

  return (
    <>
      <ReferenceInput
        reference="datapoints"
        sort={{ field: "slug", order: "ASC" }}
        source={getSource("datapoint_id")}
        validate={required()}
      >
        <AutocompleteInput
          label="Datapoint"
          optionText={datapointSelectRenderer}
          validate={required()}
        />
      </ReferenceInput>
      <SelectInput
        source={getSource("operator")}
        {...operatorInputOptions[data?.datapoint_type ?? ""]}
      />
      <Box alignSelf="center">
        <BooleanInput
          disabled={!data}
          label="variable"
          source={getSource("variable_comparison")}
          validate={required()}
        />
      </Box>
      {!compareVariable && conditionValueInputRenderer(getSource, datapointId)}
      {compareVariable &&
        data?.datapoint_type?.endsWith("_range") &&
        compareWithVariable(
          getSource,
          data?.datapoint_type?.replace("_range", "")
        )}
    </>
  );
};

const ConditionVariableForm = ({
  getSource,
}: {
  getSource: (source: string) => string;
}) => (
  <>
    <ReferenceInput
      reference="variables"
      sort={{ field: "name", order: "ASC" }}
      source={getSource("required_variable_attributes.variable_id")}
      validate={required()}
    >
      <AutocompleteInput
        label="Variable"
        optionText={variableSelectRenderer}
        validate={required()}
      />
    </ReferenceInput>
    <OffsetInput prefix={getSource("required_variable_attributes")} />
  </>
);

const ConditionDateForm = ({
  getSource,
}: {
  getSource: (source: string) => string;
}) => (
  <>
    <TextInput label="Year" source={getSource("current_date_year_string")} />
    <TextInput label="Month" source={getSource("current_date_month_string")} />
    <TextInput label="Day" source={getSource("current_date_day_string")} />
  </>
);

const helperText = (condition_type: ConditionType) => {
  switch (condition_type) {
    case ConditionType.Datapoint:
      return "Checks if the value of the datapoint matches the provided criteria";

    case ConditionType.Variable:
      return "Checks the existence of a variable for a date with the provided offset (to today's date). Positive numbers are in the future, negative in the past.";

    default:
      return "Checks if the components of today's date match the values provided. Multiple options per element can be provided in a comma-separated string.";
  }
};

const ConditionsForm = () => (
  <ArrayInput source="conditions">
    <SimpleFormIterator
      disableClear
      fullWidth
      inline
      getItemLabel={(index) => `#${index + 1}`}
    >
      <FormDataConsumer>
        {({ scopedFormData, getSource }) => {
          const datapoint_id = scopedFormData?.datapoint_id;
          const condition_type =
            scopedFormData?.condition_type || ConditionType.Datapoint;

          if (!getSource) return null;

          return (
            <>
              <SelectInput
                choices={[
                  { id: ConditionType.Datapoint, name: "Datapoint" },
                  { id: ConditionType.Variable, name: "Variable" },
                  { id: ConditionType.Date, name: "Date" },
                ]}
                defaultValue={ConditionType.Datapoint}
                fullWidth={true}
                helperText={helperText(condition_type)}
                source={getSource("condition_type")}
                validate={required()}
              />

              {condition_type === ConditionType.Datapoint && (
                <ConditionDatapointForm
                  datapointId={datapoint_id}
                  getSource={getSource}
                />
              )}
              {condition_type === ConditionType.Variable && (
                <ConditionVariableForm getSource={getSource} />
              )}
              {condition_type === ConditionType.Date && (
                <ConditionDateForm getSource={getSource} />
              )}
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

const EdgeForm = ({
  nextOrder,
  nodeFormType,
  setNodeFormType,
  wizardId,
}: {
  nextOrder: number;
  nodeFormType: NodeFormType;
  setNodeFormType: (type: NodeFormType) => void;
  wizardId: number;
}) => {
  const record = useRecordContext<EdgeData>();
  useEffect(() => setNodeFormType(initialNodeFormType(record)), []);

  return (
    <SimpleForm defaultValues={{ order: nextOrder }} toolbar={<EdgeToolbar />}>
      <NumberInput fullWidth source="order" validate={required()} />
      <ConditionsForm />
      <MarkdownInput interpolation source="reply" />
      <Labeled label="Node">
        <>
          <ButtonGroup>
            <Button
              variant={
                nodeFormType === NodeFormType.Existing
                  ? "contained"
                  : "outlined"
              }
              onClick={() => setNodeFormType(NodeFormType.Existing)}
            >
              Existing node
            </Button>
            <Button
              variant={
                nodeFormType === NodeFormType.New ? "contained" : "outlined"
              }
              onClick={() => setNodeFormType(NodeFormType.New)}
            >
              New node
            </Button>
            <Button
              variant={
                nodeFormType === NodeFormType.Continue
                  ? "contained"
                  : "outlined"
              }
              onClick={() => setNodeFormType(NodeFormType.Continue)}
            >
              Continue
            </Button>
            <Button
              variant={
                nodeFormType === NodeFormType.Finish ? "contained" : "outlined"
              }
              onClick={() => setNodeFormType(NodeFormType.Finish)}
            >
              Finish
            </Button>
          </ButtonGroup>
          {renderNodeForm(nodeFormType, wizardId)}
        </>
      </Labeled>
    </SimpleForm>
  );
};

export default EdgeForm;
