export const categories = [
  { id: "Belasting", name: "Belasting" },
  { id: "Financiële onafhankelijkheid", name: "Financiële onafhankelijkheid" },
  { id: "Geld", name: "Geld" },
  { id: "Gezondheid", name: "Gezondheid" },
  { id: "Pensioen", name: "Pensioen" },
  { id: "Recht", name: "Recht" },
  { id: "Relaties", name: "Relaties" },
  { id: "Studie", name: "Studie" },
  { id: "Werk", name: "Werk" },
  { id: "Wonen", name: "Wonen" },
];

export const subCategories: Record<string, Record<"id" | "name", string>[]> = {
  Belasting: [
    { id: "Toeslagen", name: "Toeslagen" },
    { id: "Aangifte", name: "Aangifte" },
  ],
  Financiële: [],
  Geld: [
    { id: "Schulden", name: "Schulden" },
    { id: "Sparen", name: "Sparen" },
    { id: "Beleggen", name: "Beleggen" },
    { id: "Geldzorgen", name: "Geldzorgen" },
  ],
  Gezondheid: [
    { id: "Arbeidsongeschikt", name: "Arbeidsongeschikt" },
    { id: "Ziek", name: "Ziek" },
  ],
  Pensioen: [
    { id: "AOW", name: "AOW" },
    { id: "Werken naast pensioen", name: "Werken naast pensioen" },
  ],
  Recht: [],
  Relaties: [
    { id: "Kinderen", name: "Kinderen" },
    { id: "Zwangerschap", name: "Zwangerschap" },
    { id: "Trouwen", name: "Trouwen" },
    { id: "Scheiden", name: "Scheiden" },
    { id: "Overlijden", name: "Overlijden" },
  ],
  Studie: [],
  Werk: [
    { id: "Ondernemer", name: "Ondernemer" },
    { id: "Werkloos", name: "Werkloos" },
    { id: "Ontslag", name: "Ontslag" },
  ],
  Wonen: [
    { id: "Huren", name: "Huren" },
    { id: "Kopen", name: "Kopen" },
    { id: "Buitenland", name: "Buitenland" },
  ],
};
