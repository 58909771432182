import WizardCreate from "./WizardCreate";
import WizardEdit from "./WizardEdit";
import WizardList from "./WizardList";
import WizardShow from "./WizardShow";

export default {
  create: WizardCreate,
  edit: WizardEdit,
  list: WizardList,
  show: WizardShow,
};
