import IconContentCreate from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import {
  ArrayField,
  BooleanField,
  Button,
  Labeled,
  Link,
  ReferenceField,
  Show,
  TextField,
  useRecordContext,
  useRedirect,
  useShowController,
} from "react-admin";

import { NodeData } from "../../../../types/models";
import { FormType } from "../../components/CreateOrEdit";
import MarkdownField from "../../components/MarkdownField";
import CalculationDatagrid from "../calculations/components/CalculationDatagrid";
import { Direction } from "../edges/components/EdgeForm";
import EdgeFormButton from "../edges/components/EdgeFormButton";
import NodeAnswerButtons from "../node_answer_buttons/components/NodeAnswerButtons";

import { NodeAnswerType } from "./components/NodeAnswerTypeInput";
import RelatedNodes from "./components/RelatedNodes";

const EditNodeButton = () => {
  const record = useRecordContext<NodeData>();
  const redirect = useRedirect();

  if (!record) return null;

  const onClick = () => {
    redirect("edit", "nodes", record.id);
  };

  return (
    <Button
      label="Edit"
      startIcon={<IconContentCreate />}
      sx={{ verticalAlign: "bottom" }}
      onClick={onClick}
    />
  );
};

const NodeDetails = () => (
  <Grid container spacing={2}>
    <Grid item md={2} xs={4}>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
    </Grid>
    <Grid item md={2} xs={4}>
      <Labeled label="Label">
        <TextField source="slug" />
      </Labeled>
    </Grid>
    <Grid item md={2} xs={4}>
      <Labeled label="Valid">
        <BooleanField source="valid_for_wizard?" />
      </Labeled>
    </Grid>
    <Grid item md={2} xs={4}>
      <Labeled label="Datapoint">
        <ReferenceField
          link="show"
          reference="datapoints"
          source="datapoint_id"
        >
          <TextField source="slug" />
        </ReferenceField>
      </Labeled>
    </Grid>
    <Grid item md={2} xs={6}>
      <Labeled label="Datapoint type">
        <TextField source="datapoint_type" />
      </Labeled>
    </Grid>
    <Grid item md={2} xs={6}>
      <EditNodeButton />
    </Grid>
  </Grid>
);

const DatapointNode: FC<{
  record: NodeData;
}> = ({ record }) => {
  return (
    <>
      <NodeDetails />
      <Divider />
      {!record["first_node?"] && (
        <>
          <Typography display="block" marginTop="1em" textAlign="center">
            <MarkdownField source="question_preview" />
          </Typography>
          {[NodeAnswerType.Buttons, NodeAnswerType.PensionProvider].includes(
            record.answer_type!
          ) ? (
            <NodeAnswerButtons />
          ) : (
            <Labeled alignItems="center" label="Answer type" width="100%">
              <TextField marginBottom="1em" source="answer_type" />
            </Labeled>
          )}
          <Divider />
        </>
      )}
      <Typography gutterBottom variant="h6">
        Calculations
      </Typography>
      <ArrayField source="calculations">
        <CalculationDatagrid rowClick={false} />
      </ArrayField>
    </>
  );
};

const ContinueNode: FC<{
  record: NodeData;
}> = ({ record }) => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect("edit", "nodes", record.id);
  };

  return (
    <Box marginX="0.2em" textAlign="center">
      <Tooltip title="test">
        <Chip
          deleteIcon={<EditIcon />}
          label={<TextField source="continue_button" />}
          onClick={handleClick}
          onDelete={handleClick}
        />
      </Tooltip>
    </Box>
  );
};

const NodeShow = () => {
  const { record } = useShowController<NodeData>();

  if (!record) return null;

  return (
    <Show actions={<></>} component={Box}>
      <Stack direction="column" spacing={1}>
        <Link marginTop="1em" to={`/wizards/${record.wizard_id}/show`}>
          {"< To wizard"}
        </Link>
        <RelatedNodes direction={Direction.In} />
        <Card variant="outlined">
          <CardContent>
            {record.continue_button ? (
              <ContinueNode record={record} />
            ) : (
              <DatapointNode record={record} />
            )}
          </CardContent>
        </Card>
        <RelatedNodes direction={Direction.Out} />
        <EdgeFormButton
          direction={Direction.Out}
          formType={FormType.CreateForm}
          title="Connect new node"
        />
      </Stack>
    </Show>
  );
};

export default NodeShow;
