import { Typography } from "@mui/material";
import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  useGetList,
} from "react-admin";

import { ClassifierData } from "../../../../types/models";
import { classifierSelectRenderer } from "../datapoints/lib/renderers";

const ClassifierResultCreate = () => {
  const { data: activeClassifier, isLoading } = useGetList<ClassifierData>(
    "classifiers",
    {
      filter: { active: true },
      pagination: { page: 1, perPage: 100 },
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Create redirect="list">
      <SimpleForm>
        <Typography variant="h6">Classify new result</Typography>

        <TextInput source="text" />
        <ReferenceInput
          defaultValue={1}
          name="classifier_id"
          reference="classifiers"
          source="classifier_id"
        >
          <AutocompleteInput
            defaultValue={activeClassifier?.[0]?.id}
            label="Classifier"
            optionText={classifierSelectRenderer}
            validate={required()}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ClassifierResultCreate;
