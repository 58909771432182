export const formatNumber = (number: number) =>
  number.toLocaleString("nl-NL", {
    maximumFractionDigits: 0,
    useGrouping: true,
  });

export const formatEuro = (number: number) =>
  "€ " +
  number
    .toLocaleString("nl-NL", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      useGrouping: true,
    })
    .replace(",00", "");
