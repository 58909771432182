import React from "react";
import { NumberInput } from "react-admin";

const OffsetInput = ({
  helperText,
  prefix,
}: {
  helperText?: boolean;
  prefix?: string;
}) => (
  <>
    <div>
      <NumberInput
        helperText={
          helperText
            ? "Positive numbers are in the future, negative numbers in the past"
            : undefined
        }
        label="Offset in years"
        source={prefix ? `${prefix}.offset_years` : "offset_years"}
      />
    </div>
    <div>
      <NumberInput
        helperText={
          helperText
            ? "Positive numbers are in the future, negative numbers in the past"
            : undefined
        }
        label="Offset in months"
        source={prefix ? `${prefix}.offset_months` : "offset_months"}
      />
    </div>
    <div>
      <NumberInput
        helperText={
          helperText
            ? "Positive numbers are in the future, negative numbers in the past"
            : undefined
        }
        label="Offset in days"
        source={prefix ? `${prefix}.offset_days` : "offset_days"}
      />
    </div>
  </>
);

export default OffsetInput;
