import { FormHelperText } from "@mui/material";
import { Editor } from "@toast-ui/react-editor";
import { useInput } from "ra-core";
import React, { useRef } from "react";
import { InputProps, Labeled, useTranslateLabel } from "react-admin";

import useDatapointInterpolationPlugin from "../lib/useDatapointInterpolationPlugin";
import useVariableInterpolationPlugin from "../lib/useVariableInterpolationPlugin";

import DatapointInterpolationDialog from "./DatapointInterpolationDialog";
import VariableInterpolationDialog from "./VariableInterpolationDialog";

import "@toast-ui/editor/dist/toastui-editor.css";

interface MarkdownInputProps extends InputProps {
  interpolation?: boolean;
}

const toolbarItems = [
  ["heading", "bold", "italic", "strike"],
  ["hr", "quote"],
  ["ul", "ol"],
  ["table", "image", "link"],
];

const MarkdownInput = ({
  helperText,
  interpolation,
  label,
  resource,
  source,
  ...inputProps
}: MarkdownInputProps) => {
  const editorRef = useRef<Editor>(null);
  const [
    datapointInterpolationPlugin,
    datapointInterpolationWidget,
    showDatapointDialog,
    closeDatapointDialog,
  ] = useDatapointInterpolationPlugin(editorRef.current?.getInstance());
  const [
    variableInterpolationPlugin,
    variableInterpolationWidget,
    showVariableDialog,
    closeVariableDialog,
  ] = useVariableInterpolationPlugin(editorRef.current?.getInstance());
  const {
    field,
    fieldState: { error },
    isRequired,
  } = useInput({ resource, source, ...inputProps });
  const translateLabel = useTranslateLabel();

  const handleChange = () => {
    field.onChange(editorRef.current!.getInstance().getMarkdown());
  };

  if (!datapointInterpolationWidget || !variableInterpolationWidget) {
    return null;
  }

  return (
    <Labeled
      isRequired={isRequired}
      label={label ?? translateLabel({ source })}
    >
      <>
        <Editor
          initialEditType="wysiwyg"
          initialValue={field.value}
          plugins={
            interpolation
              ? [datapointInterpolationPlugin, variableInterpolationPlugin]
              : []
          }
          ref={editorRef}
          toolbarItems={toolbarItems}
          usageStatistics={false}
          widgetRules={
            interpolation
              ? [datapointInterpolationWidget, variableInterpolationWidget]
              : []
          }
          onBlur={field.onBlur}
          onChange={handleChange}
        />
        <FormHelperText error={!!error?.message}>
          {error?.message ?? helperText ?? " "}
        </FormHelperText>
        <DatapointInterpolationDialog
          editor={editorRef.current?.getInstance()}
          handleClose={closeDatapointDialog}
          show={showDatapointDialog}
        />
        <VariableInterpolationDialog
          editor={editorRef.current?.getInstance()}
          handleClose={closeVariableDialog}
          show={showVariableDialog}
        />
      </>
    </Labeled>
  );
};

export default MarkdownInput;
