import React from "react";
import { Datagrid, DeleteButton, TextField } from "react-admin";

import { FormType } from "../../../components/CreateOrEdit";

import DatapointOptionFormButton from "./DatapointOptionFormButton";

const DatapointOptionDatagrid = () => (
  <Datagrid bulkActionButtons={false}>
    <TextField source="id" />
    <TextField source="value" />
    <DatapointOptionFormButton type={FormType.EditForm} />
    <DeleteButton mutationMode="pessimistic" redirect={false} />
  </Datagrid>
);

export default DatapointOptionDatagrid;
