import React from "react";
import {
  AutocompleteInput,
  DeleteButton,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarClasses,
  ToolbarProps,
  required,
} from "react-admin";

const ClassifierSampleToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton alwaysEnable />
      <DeleteButton mutationMode="pessimistic" redirect={false} />
    </div>
  </Toolbar>
);

const ClassifierSampleForm = ({
  defaultValues,
  showWizardSelect,
}: {
  defaultValues?: { text: string; wizard_id: number };
  showWizardSelect?: boolean;
}) => (
  <SimpleForm
    defaultValues={defaultValues}
    toolbar={<ClassifierSampleToolbar />}
  >
    <TextInput fullWidth source="text" validate={required()} />
    {showWizardSelect && (
      <ReferenceInput label="Wizard" reference="wizards" source="wizard_id">
        <AutocompleteInput fullWidth label="Wizard" optionText="question" />
      </ReferenceInput>
    )}
  </SimpleForm>
);

export default ClassifierSampleForm;
