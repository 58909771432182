import { Button, CardContent, Typography } from "@mui/material";
import * as React from "react";
import { Login, LoginFormClasses } from "react-admin";

import { csrfToken } from "../lib/api";

const LoginButton = () => {
  return (
    <CardContent className={LoginFormClasses.content}>
      <Typography align="center" gutterBottom={true} variant="h4">
        Statemachine admin
      </Typography>
      <Typography gutterBottom={true}>
        Welcome to the Statemachine administration interface.
      </Typography>
      <Typography paragraph={true}>
        You can login via Google OAuth by clicking the button below.
      </Typography>
      <form action="/auth/google_kandoor" className="button_to" method="post">
        <Button
          fullWidth
          className={LoginFormClasses.button}
          color="primary"
          type="submit"
          variant="contained"
        >
          Sign in with Google
        </Button>
        <input
          autoComplete="off"
          name="authenticity_token"
          type="hidden"
          value={csrfToken}
        />
      </form>
    </CardContent>
  );
};

const MyLoginPage = () => {
  return (
    <Login>
      <LoginButton />
    </Login>
  );
};

export default MyLoginPage;
