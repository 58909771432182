import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import {
  Datagrid,
  Identifier,
  ListContextProvider,
  NumberField,
  TextField,
  useGetList,
  useList,
} from "react-admin";

const ClassifierWizardList = () => {
  const classifierRowClick = (id: Identifier) => "/wizards/" + id + "/show";
  const { data, isLoading } = useGetList("wizards", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" },
  });
  const listContext = useList({ data, isLoading });

  return (
    <Card
      sx={{
        marginTop: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6">Classifier sample counts</Typography>
      </CardContent>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false} rowClick={classifierRowClick}>
          <NumberField source="id" />
          <TextField source="slug" />
          <TextField source="question" />
          <NumberField source="classifier_samples_count" />
        </Datagrid>
      </ListContextProvider>
    </Card>
  );
};

export default ClassifierWizardList;
