import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ToastuiEditor from "@toast-ui/editor";
import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useGetOne,
} from "react-admin";
import { useWatch } from "react-hook-form";

import { VariableData } from "../../../types/models";
import { VariableType } from "../resources/variables/lib/types";

import OffsetInput from "./OffsetInput";

const FORMAT_OPTIONS: Record<VariableType, Record<string, string>[]> = {
  [VariableType.Number]: [{ id: "default", name: "default" }],
  [VariableType.NumberArray]: [{ id: "default", name: "default" }],
  [VariableType.Money]: [{ id: "euro", name: "euro" }],
  [VariableType.MoneyArray]: [{ id: "euro", name: "euro" }],
  [VariableType.Date]: [{ id: "date", name: "date" }],
  [VariableType.DateArray]: [{ id: "date", name: "date" }],
};

const FormatInput = () => {
  const variableId = useWatch({ name: "variable_id" });
  const { data: variable } = useGetOne<VariableData>(
    "variables",
    {
      id: variableId!,
    },
    { enabled: !!variableId }
  );

  if (!variable) {
    return null;
  }

  return (
    <SelectInput
      fullWidth
      choices={FORMAT_OPTIONS[variable.variable_type]}
      label="Format"
      source="format"
      validate={required()}
    />
  );
};

const VariableInterpolationDialog = ({
  editor,
  handleClose,
  show,
}: {
  editor?: ToastuiEditor;
  handleClose: () => void;
  show: boolean;
}) => {
  const handleSubmit = (values: Record<string, string | number>) => {
    if (editor) {
      editor.insertText(
        `<<${values.variable_id}=${values.format}=${values.offset_years ?? 0}=${
          values.offset_months ?? 0
        }=${values.offset_days ?? 0}>>`
      );
    }
    handleClose();
  };

  return (
    <Dialog
      aria-label="Insert variable"
      maxWidth="md"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>Insert variable</DialogTitle>
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          reference="variables"
          sort={{ field: "name", order: "ASC" }}
          source="variable_id"
        >
          <AutocompleteInput
            fullWidth
            label="Variable"
            optionText="name_with_value"
            validate={required()}
          />
        </ReferenceInput>
        <OffsetInput helperText />
        <FormatInput />
      </SimpleForm>
    </Dialog>
  );
};

export default VariableInterpolationDialog;
