import { Box } from "@mui/material";
import React from "react";
import {
  ArrayField,
  Datagrid,
  DatagridHeader,
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";

import { FormType } from "../../../components/CreateOrEdit";
import ClassifierSampleFormButton from "../../classifier_samples/components/ClassifierSampleFormButton";

const ClassifierSampleCreateButton = () => {
  const classifierResult = useRecordContext();

  return (
    <ClassifierSampleFormButton
      defaultValues={{
        text: classifierResult.text,
        wizard_id: classifierResult.wizard_id,
      }}
      formType={FormType.CreateForm}
      showWizardSelect={true}
    />
  );
};

const PredictionDatagrid = () => {
  return (
    <Datagrid
      bulkActionButtons={false}
      header={
        <Box display="none">
          <DatagridHeader />
        </Box>
      }
    >
      <ReferenceField link="show" reference="wizards" source="id">
        <TextField source="question" />
      </ReferenceField>
      <NumberField source="score" />
    </Datagrid>
  );
};

const ClassifierResultDatagrid = () => {
  return (
    <Datagrid bulkActionButtons={false} rowClick="show">
      <NumberField source="id" />
      <ReferenceField reference="classifiers" source="classifier_id">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="text" />
      <ArrayField source="prediction">
        <PredictionDatagrid />
      </ArrayField>
      <ReferenceField reference="wizards" source="as_sample_in_wizard_id">
        <TextField source="question" />
      </ReferenceField>
      <ClassifierSampleCreateButton />
      <DateField showTime={true} source="created_at" />
    </Datagrid>
  );
};

export default ClassifierResultDatagrid;
