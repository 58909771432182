import React from "react";
import { Datagrid, List, TextField } from "react-admin";

const VariableList = () => (
  <List perPage={50} sort={{ field: "name", order: "ASC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="variable_type" />
    </Datagrid>
  </List>
);

export default VariableList;
