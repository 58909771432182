import { Grid } from "@mui/material";
import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TransformData,
  required,
} from "react-admin";

import { CalculationParameterData } from "../../../../types/models";
import { datapointSelectRenderer } from "../datapoints/lib/renderers";

const CalculationDatapointOptions = ({
  datapoint_id,
  source,
}: CalculationParameterData & {
  source: string | undefined;
}) => {
  if (!datapoint_id || !source) {
    return null;
  }

  return (
    <ArrayInput label={false} source={source}>
      <SimpleFormIterator
        disableAdd
        disableClear
        disableRemove
        disableReordering
        fullWidth
        inline
      >
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              getSource && (
                <>
                  <Labeled label={scopedFormData.key}>
                    <ReferenceInput
                      filter={{ datapoint: datapoint_id }}
                      reference="datapoint_options"
                      source={getSource("datapoint_option_id")}
                    >
                      <AutocompleteInput
                        fullWidth
                        label="Option"
                        optionText="value"
                        validate={required()}
                      />
                    </ReferenceInput>
                  </Labeled>
                </>
              )
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
const ParameterInputArrayWithDatagrid = ({ source }: { source: string }) => (
  <ArrayInput source={source}>
    <SimpleFormIterator
      disableAdd
      disableClear
      disableRemove
      disableReordering
      fullWidth
      inline
    >
      <FormDataConsumer>
        {({ scopedFormData, getSource }) => (
          <>
            <Grid container spacing={2}>
              <Grid item md={3} xs={6}>
                <TextField record={scopedFormData} source="parameter_name" />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField record={scopedFormData} source="type" />
              </Grid>
              <Grid item md={6} xs={12}>
                {getSource && scopedFormData && (
                  <ReferenceInput
                    filter={{ datapoint_type: scopedFormData.type }}
                    reference="datapoints"
                    source={getSource("datapoint_id")}
                  >
                    <AutocompleteInput
                      fullWidth
                      optionText={datapointSelectRenderer}
                    />
                  </ReferenceInput>
                )}
                {scopedFormData.type === "options" && (
                  <CalculationDatapointOptions
                    {...scopedFormData}
                    source={
                      getSource &&
                      getSource("calculation_datapoint_options_attributes")
                    }
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

const CalculationEdit = () => {
  const transform: TransformData = (data) => {
    delete data["type_name"];

    return data;
  };

  return (
    <Edit mutationMode="pessimistic" transform={transform}>
      <SimpleForm>
        <ParameterInputArrayWithDatagrid source="input_parameters" />
        <ParameterInputArrayWithDatagrid source="output_parameters" />
      </SimpleForm>
    </Edit>
  );
};

export default CalculationEdit;
