import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const WizardCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="question" />
    </SimpleForm>
  </Create>
);

export default WizardCreate;
