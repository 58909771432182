import { Divider, Stack } from "@mui/material";
import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";

import MarkdownInput from "../../../components/MarkdownInput";
import { calculationSelectRenderer } from "../../calculations/lib/renderers";
import DatapointCreateButton from "../../datapoints/components/DatapointCreateButton";
import { datapointSelectRenderer } from "../../datapoints/lib/renderers";
import { wizardSelectRenderer } from "../../wizards/lib/renderers";

import { DatapointDetails } from "./DatapointDetails";
import { NodeAnswerTypeInput } from "./NodeAnswerTypeInput";
import { NodeToolbar } from "./NodeToolbar";

const NodeForm = () => {
  const record = useRecordContext();

  return (
    <SimpleForm toolbar={<NodeToolbar />}>
      <Stack
        direction="column"
        divider={<Divider flexItem orientation="horizontal" />}
        spacing={1}
        sx={{ width: 1 }}
      >
        {!record["first_node?"] && (
          <>
            <ReferenceInput
              reference="datapoints"
              sort={{ field: "slug", order: "ASC" }}
              source="datapoint_id"
            >
              <AutocompleteInput
                fullWidth
                helperText={false}
                label="Datapoint"
                optionText={datapointSelectRenderer}
                validate={required()}
              />
            </ReferenceInput>
            <DatapointCreateButton />
            <DatapointDetails />
            <NodeAnswerTypeInput />
            <TextInput fullWidth source="slug" validate={required()} />
            <MarkdownInput
              interpolation
              source="question"
              validate={required()}
            />
            <ReferenceInput
              reference="wizards"
              sort={{ field: "id", order: "ASC" }}
              source="wizard_id"
            >
              <AutocompleteInput
                fullWidth
                label="Wizard"
                optionText={wizardSelectRenderer}
                validate={required()}
              />
            </ReferenceInput>
          </>
        )}

        <ArrayInput source="calculations">
          <SimpleFormIterator fullWidth>
            <ReferenceInput reference="calculations" source="id">
              <AutocompleteInput
                fullWidth
                label="Calculation"
                optionText={calculationSelectRenderer}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </Stack>
    </SimpleForm>
  );
};

export default NodeForm;
