import { Typography } from "@mui/material";
import React from "react";
import {
  BooleanInput,
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";

import filterDirtyFields from "../../lib/filterDirtyFields";

const ClassifierSaveButton = () => {
  return <SaveButton alwaysEnable={true} />;
};

const ClassifierEditToolbar = () => (
  <Toolbar>
    <ClassifierSaveButton />
  </Toolbar>
);
const ClassifierEdit = () => {
  return (
    <Edit mutationMode="pessimistic" transform={filterDirtyFields}>
      <SimpleForm toolbar={<ClassifierEditToolbar />}>
        <Typography variant="h6">Edit classifier </Typography>
        <BooleanInput name="active" source="active" />
      </SimpleForm>
    </Edit>
  );
};

export default ClassifierEdit;
