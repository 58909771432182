import React from "react";
import {
  ArrayField,
  Datagrid,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

import { DatapointData } from "../../../../types/models";

const ParameterArrayWithDatagrid = ({ source }: { source: string }) => (
  <ArrayField source={source}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="parameter_name" />
      <TextField source="type" />
      <ReferenceField reference="datapoints" source="datapoint_id">
        <FunctionField
          render={(record: DatapointData) => `${record.slug} (${record.id})`}
        />
      </ReferenceField>
    </Datagrid>
  </ArrayField>
);

const CalculationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="type_name" />
      <ParameterArrayWithDatagrid source="input_parameters" />
      <ParameterArrayWithDatagrid source="output_parameters" />
    </SimpleShowLayout>
  </Show>
);

export default CalculationShow;
