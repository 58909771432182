import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

import { variableTypeChoices } from "./lib/types";

const VariableCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <SelectInput
        choices={variableTypeChoices}
        source="variable_type"
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

export default VariableCreate;
