import React from "react";
import { Create } from "react-admin";

import NodeForm from "./components/NodeForm";

export const NodeCreate = () => (
  <Create redirect="show">
    <NodeForm />
  </Create>
);

export default NodeCreate;
