import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SellIcon from "@mui/icons-material/Sell";
import ShareIcon from "@mui/icons-material/Share";
import React from "react";
import { Admin, Resource } from "react-admin";

import authProvider from "../authentication/authProvider";
import dataProvider from "../lib/dataProvider";
import calculationPages from "../resources/calculations";
import classifierResultsPages from "../resources/classifier_results";
import classifierPages from "../resources/classifiers";
import datapointPages from "../resources/datapoints";
import nodePages from "../resources/nodes";
import variablePages from "../resources/variables";
import wizardPages from "../resources/wizards";

import LoginPage from "./LoginPage";

const AdminApp = ({ testRoot }: { testRoot: string }) => {
  window.STM_testRoot = testRoot;

  return (
    <Admin
      requireAuth
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
    >
      <Resource name="wizards" {...wizardPages} icon={AccountTreeIcon} />
      <Resource name="nodes" {...nodePages} />
      <Resource name="datapoints" {...datapointPages} icon={AssessmentIcon} />
      <Resource name="datapoint_options" />
      <Resource name="variables" {...variablePages} icon={CalendarMonthIcon} />
      <Resource name="edges" />
      <Resource name="node_answer_buttons" />
      <Resource name="classifier_samples" />
      <Resource
        name="calculations"
        {...calculationPages}
        icon={CalculateIcon}
      />
      <Resource name="classifiers" {...classifierPages} icon={ShareIcon} />
      <Resource
        name="classifier_results"
        {...classifierResultsPages}
        icon={SellIcon}
      />
    </Admin>
  );
};

export default AdminApp;
