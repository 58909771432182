import { Identifier } from "ra-core";
import React from "react";
import {
  Loading,
  useGetManyReference,
  useRecordContext,
  useRedirect,
} from "react-admin";

import {
  CalculationData,
  EdgeData,
  NodeData,
  WizardData,
} from "../../../../../types/models";
import Mermaid from "../../../components/Mermaid";
import { formatNumber } from "../../../lib/numbers";
import calculationName from "../../calculations/lib/calculationName";

const sanitizeDescription = (string?: string) => {
  return string?.replace(/"/g, "#quot;") ?? "no question";
};

const calculationMarkdown = (
  node: NodeData,
  calculation: CalculationData,
  index: number
) => {
  const calculationNodeId = `${node.id}-${calculation.id}`;

  let result = `${calculationNodeId}{{"${calculationName(calculation)}"}}\n`;
  result += `class ${calculationNodeId} calculation\n`;

  if (index === 0) {
    result += `${node.id}-->${calculationNodeId}\n`;
  } else {
    result += `${node.id}-${
      node.calculations[index - 1].id
    }-->${calculationNodeId}\n`;
  }

  return result;
};

const edgeMarkdown = (fromNode: Identifier, edge: EdgeData) => {
  let result = `${fromNode}-->|"${edge.order}`;

  if (edge.conditions.length > 0) {
    result += "\n";
    result += edge.conditions
      .map((condition) => condition.condition_string)
      .join("\n");
  }
  result += `\nFollowed: ${formatNumber(edge.steps_count)}"|${
    edge.to_node_id ?? "endNode"
  }\n`;

  return result;
};

const nodesMarkdown = (node: NodeData, edgesData: EdgeData[]) => {
  let result = `${node.id}("${sanitizeDescription(
    node.slug ?? node.continue_button
  )}\nShown: ${formatNumber(node.steps_count)}")\n`;
  result += `click ${node.id} call openNode()\n`;
  if (node["first_node?"]) {
    result += `class ${node.id} startNode\n`;
  } else if (node.continue_button && node.continue_button.length > 0) {
    result += `class ${node.id} continue\n`;
  }

  node.calculations?.forEach((calculation, index) => {
    result += calculationMarkdown(node, calculation, index);
  });

  const fromNode =
    node.calculations.length > 0
      ? `${node.id}-${node.calculations[node.calculations.length - 1].id}`
      : node.id;

  edgesData
    .filter((edge) => edge.from_node_id.toString() === node.id)
    .forEach((edge) => {
      result += edgeMarkdown(fromNode, edge);
    });

  return result;
};

const generateMarkdown = (
  nodesData: NodeData[],
  edgesData: EdgeData[],
  wizard: WizardData
) => {
  let result =
    "graph TB\nclassDef startNode fill:#94c6f2\nclassDef endNode fill:#94c6f2\nclassDef calculation fill:#fffc7b\nclassDef continue fill:#98e4e3\n";

  result += `endNode("Finished: ${formatNumber(
    wizard.finished_journeys_count
  )}\nHelpful: ${formatNumber(
    wizard.helpful_journeys_count
  )}\nUnhelpful: ${formatNumber(
    wizard.unhelpful_journeys_count
  )}")\nclass endNode endNode\n`;

  nodesData.forEach((node) => {
    result += nodesMarkdown(node, edgesData);
  });

  return result;
};

const NodeEdgeGraph = () => {
  const record = useRecordContext<WizardData>();
  const redirect = useRedirect();

  window.openNode = async function (nodeId: number) {
    redirect("show", "nodes", nodeId);
  };

  const {
    data: nodesData,
    isLoading: nodesLoading,
    error: nodesError,
  } = useGetManyReference<NodeData>("nodes", {
    id: record.id,
    pagination: { page: 1, perPage: 100 },
    target: "wizard",
  });

  const {
    data: edgesData,
    isLoading: edgesLoading,
    error: edgesError,
  } = useGetManyReference<EdgeData>("edges", {
    id: record.id,
    pagination: { page: 1, perPage: 100 },
    sort: { field: "order", order: "ASC" },
    target: "wizard",
  });

  if (nodesLoading || edgesLoading) {
    return <Loading />;
  }

  if (
    nodesError ||
    edgesError ||
    nodesData === undefined ||
    edgesData === undefined
  ) {
    return (
      <p>
        Error rendering graph: if this problem persists please contact the
        developers.
      </p>
    );
  }

  if (nodesData.length === 0 && edgesData.length === 0) {
    return (
      <p>
        There are no nodes in this wizard yet, so a graph cannot be displayed.
      </p>
    );
  }

  return <Mermaid chart={generateMarkdown(nodesData, edgesData, record)} />;
};

export default NodeEdgeGraph;
