import { Stack } from "@mui/material";
import React from "react";
import { List } from "react-admin";

import ClassifierResultDatagrid from "./components/ClassifierResultDataGrid";

const ClassifierResultList = () => {
  return (
    <Stack>
      <List
        exporter={false}
        filter={{ classifier_type: "question" }}
        perPage={100}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <ClassifierResultDatagrid />
      </List>
    </Stack>
  );
};

export default ClassifierResultList;
