import IconContentAdd from "@mui/icons-material/Add";
import IconContentCreate from "@mui/icons-material/Create";
import { TransformData } from "ra-core";
import React, { useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";

import { VariableValueData } from "../../../../../types/models";
import CreateOrEdit, { FormType } from "../../../components/CreateOrEdit";
import DialogButton from "../../../components/DialogButton";

import VariableValueForm from "./VariableValueForm";

const VariableValueFormButton = ({ type }: { type: FormType }) => {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<VariableValueData>();

  const createBoolean = type === FormType.CreateForm;
  const title = `${createBoolean ? "Create" : "Edit"} variable value `;

  const onSuccess = () => {
    notify("Changes saved");
    refresh();
    setShowDialog(false);
  };

  const transform: TransformData = (data) => {
    const returnData = data;

    delete returnData["variable_type"];

    if (createBoolean) {
      returnData["variable_id"] = record.id;
    }

    return returnData;
  };

  return (
    <DialogButton
      fullWidth={createBoolean}
      icon={createBoolean ? <IconContentAdd /> : <IconContentCreate />}
      setShowDialog={setShowDialog}
      showDialog={showDialog}
      title={title}
    >
      <CreateOrEdit
        recordId={record.id}
        resourceType="variable_values"
        transform={transform}
        type={type}
        onSuccess={onSuccess}
      >
        <VariableValueForm variable_type={record.variable_type} />
      </CreateOrEdit>
    </DialogButton>
  );
};

export default VariableValueFormButton;
