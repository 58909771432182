import React from "react";
import {
  DeleteButton,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarClasses,
  ToolbarProps,
  required,
} from "react-admin";

const DatapointOptionToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" redirect={false} />
    </div>
  </Toolbar>
);

const DatapointOptionForm = () => (
  <SimpleForm toolbar={<DatapointOptionToolbar />}>
    <TextInput fullWidth source="value" validate={required()} />
  </SimpleForm>
);

export default DatapointOptionForm;
