import { DatapointData } from "../../../../../types/models";

export const datapointSelectRenderer = (datapoint: DatapointData) =>
  `${datapoint.slug} [${datapoint.id}] (${datapoint.datapoint_type})`;

export const datapointOptionSelectRenderer = (datapointOption: {
  value: string;
}) => datapointOption.value;

export const classifierSelectRenderer = (classifier: {
  active: boolean;
  created_at: string;
  id: number;
}) =>
  `${classifier.active ? "Active " : ""}Classifier [${classifier.id}] - ${
    classifier.created_at
  }`;

export const variableSelectRenderer = (variable: {
  id: number;
  name: string;
}) => `${variable.name} [${variable.id}]`;
