import { Stack } from "@mui/material";
import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  CommonInputProps,
  Labeled,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { useWatch } from "react-hook-form";

import OffsetInput from "../../../components/OffsetInput";
import { variableSelectRenderer } from "../../datapoints/lib/renderers";

export enum RangeType {
  Value = "value",
  Variable = "variable",
}

interface RangeProps {
  attribute: string;
  input: (props: CommonInputProps) => JSX.Element;
}

const TYPE_CHOICES = [
  { id: RangeType.Value, name: "Value" },
  { id: RangeType.Variable, name: "Variable" },
];

const VariableInput = ({
  attribute,
  side,
}: {
  attribute: string;
  side: "start" | "end";
}) => {
  const prefix = `${attribute}_${side}_variable_attributes`;

  return (
    <>
      <ReferenceInput
        filter={{ variable_type: attribute }}
        reference="variables"
        sort={{ field: "name", order: "ASC" }}
        source={`${prefix}.variable_id`}
        validate={required()}
      >
        <AutocompleteInput
          label={false}
          optionText={variableSelectRenderer}
          validate={required()}
        />
      </ReferenceInput>
      <Stack direction="row" spacing={1}>
        <OffsetInput prefix={`${prefix}`} />
      </Stack>
    </>
  );
};

const Range = ({ input: Input, attribute }: RangeProps) => {
  const startType = useWatch({
    name: `${attribute}_start_type`,
  });
  const endType = useWatch({
    name: `${attribute}_end_type`,
  });

  return (
    <>
      <Labeled fullWidth label="From">
        <>
          <SelectInput
            choices={TYPE_CHOICES}
            defaultValue={RangeType.Value}
            label={false}
            source={`${attribute}_start_type`}
            validate={required()}
          />
          {startType === RangeType.Variable ? (
            <VariableInput attribute={attribute} side="start" />
          ) : (
            <Input key="start" label={false} source={`${attribute}_start`} />
          )}
          <BooleanInput label="include" source={`${attribute}_start_include`} />
        </>
      </Labeled>
      <Labeled fullWidth label="To">
        <>
          <SelectInput
            choices={TYPE_CHOICES}
            defaultValue={RangeType.Value}
            label={false}
            source={`${attribute}_end_type`}
            validate={required()}
          />
          {endType === RangeType.Variable ? (
            <VariableInput attribute={attribute} side="end" />
          ) : (
            <Input key="end" label={false} source={`${attribute}_end`} />
          )}
          <BooleanInput label="include" source={`${attribute}_end_include`} />
        </>
      </Labeled>
    </>
  );
};

export default Range;
