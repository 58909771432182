import React from "react";
import {
  BooleanInput,
  CheckboxGroupInput,
  Edit,
  SimpleForm,
  TextInput,
  useEditController,
} from "react-admin";
import { useWatch } from "react-hook-form";

import { WizardData } from "../../../../types/models";
import filterDirtyFields from "../../lib/filterDirtyFields";

import { categories, subCategories } from "./lib/categories";

const SubCategoriesInput = () => {
  const tagList = useWatch<Record<string, string[]>>({
    name: "tag_list",
  });

  return (
    <>
      {tagList.map((tag) =>
        subCategories[tag] && subCategories[tag].length > 0 ? (
          <CheckboxGroupInput
            fullWidth
            choices={subCategories[tag]}
            key={tag}
            label={tag}
            source="tag_list"
          />
        ) : null
      )}
    </>
  );
};

const WizardEdit = () => {
  const { record } = useEditController<WizardData>();

  if (!record) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      redirect="show"
      transform={filterDirtyFields}
    >
      <SimpleForm>
        <TextInput fullWidth source="question" />
        <CheckboxGroupInput
          fullWidth
          choices={categories}
          label="Tags"
          source="tag_list"
        />
        <SubCategoriesInput />
        <BooleanInput
          helperText="Show wizard in recommendations and the classifier"
          source="published"
        />
      </SimpleForm>
    </Edit>
  );
};

export default WizardEdit;
