import React from "react";
import {
  DeleteWithConfirmButton,
  ReferenceManyField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  Toolbar,
  ToolbarClasses,
  useShowController,
} from "react-admin";

import { VariableData } from "../../../../types/models";
import { FormType } from "../../components/CreateOrEdit";
import VariableValueDatagrid from "../variable_values/components/VariableValueDatagrid";
import VariableValueFormButton from "../variable_values/components/VariableValueFormButton";

import { variableTypeChoices } from "./lib/types";

const VariableShow = () => {
  const { record } = useShowController<VariableData>();

  if (!record) return null;

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <SelectField choices={variableTypeChoices} source="variable_type" />

        <ReferenceManyField
          label="Variables values"
          reference="variable_values"
          sort={{ field: "start_date", order: "ASC" }}
          target="variable"
        >
          <VariableValueDatagrid />
        </ReferenceManyField>
        <VariableValueFormButton type={FormType.CreateForm} />
      </SimpleShowLayout>
      <Toolbar>
        <div className={ToolbarClasses.defaultToolbar}>
          <div />
          <DeleteWithConfirmButton mutationMode="pessimistic" />
        </div>
      </Toolbar>
    </Show>
  );
};

export default VariableShow;
