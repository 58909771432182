import { AuthProvider } from "react-admin";

import { headers } from "../lib/api";

const NOT_AUTHORIZED = 401;
const UNPROCESSABLE_ENTITY = 422;

const authProvider: AuthProvider = {
  checkAuth: () => {
    console.log("checkAuth");
    const request = new Request("/auth/show", {
      headers: headers(),
      method: "GET",
    });

    return fetch(request)
      .then((resp: Response) => {
        if (!resp.ok) {
          return Promise.reject("You need to login");
        }

        return resp;
      })
      .then((resp: Response) => resp.json())
      .then((json) => {
        localStorage.setItem("name", json["name"]);
        localStorage.setItem("email", json["email"]);

        return Promise.resolve();
      })

      .catch(() => {
        console.log("Error caught");

        return Promise.reject("You need to login");
      });
  },
  checkError: (error) => {
    console.log("checkError");

    const status = error?.status;

    switch (status) {
      case NOT_AUTHORIZED:
        cleanupSession();

        return Promise.reject({ message: false });
      case UNPROCESSABLE_ENTITY: {
        const message = error.message?.["errors"]?.[0]?.["detail"];

        return Promise.reject({
          logoutUser: false,
          message:
            message ??
            "Processing this change gave an error: we have reloaded the page, please try again",
        });
      }
      default:
        return Promise.reject({
          logoutUser: false,
          message:
            "Processing this change gave an error: we have reloaded the page, please try again",
        });
    }

    return Promise.resolve();
  },
  getIdentity: () => {
    console.log("getIdentity");

    return Promise.resolve({
      fullName: localStorage.getItem("name") ?? "",
      id: localStorage.getItem("email") ?? "",
    });
  },
  getPermissions: () => {
    console.log("getPermissions");

    return Promise.resolve();
  },
  login: () => {
    console.log("login");

    return Promise.reject("Login only possible via Google OAuth");
  },
  logout: () => {
    console.log("logout");

    const request = new Request("/auth/destroy", {
      headers: headers(),
      method: "DELETE",
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        cleanupSession();

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  },
};

const cleanupSession = () => {
  localStorage.removeItem("name");
  localStorage.removeItem("email");
};

export default authProvider;
