import IconContentAdd from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import {
  Button,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { DatapointData } from "../../../../../types/models";
import { prefixedString } from "../../../lib/strings";
import { datapointTypeChoices } from "../lib/types";

const DatapointCreateButton = ({ prefix }: { prefix?: string }) => {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const { setValue } = useFormContext();
  const labelButton = "Create datapoint";

  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const onSuccess = (newDatapoint: DatapointData) => {
    setValue(prefixedString("datapoint_id", prefix), newDatapoint.id);
    notify("Changes saved");
    refresh();
    setShowDialog(false);
  };

  return (
    <>
      <Button label={labelButton} onClick={handleClick}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        aria-label={labelButton}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{labelButton}</DialogTitle>
        <Create
          mutationOptions={{ onSuccess }}
          record={{}}
          resource="datapoints"
        >
          <SimpleForm>
            <TextInput fullWidth source="slug" />
            <SelectInput
              fullWidth
              choices={datapointTypeChoices}
              source="datapoint_type"
              validate={required()}
            />
          </SimpleForm>
        </Create>
      </Dialog>
    </>
  );
};

export default DatapointCreateButton;
