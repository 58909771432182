import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Validator, useInput } from "react-admin";

const MoneyInput = ({
  fullWidth,
  source,
  validate,
}: {
  fullWidth?: boolean;
  source: string;
  validate?: Validator | Validator[];
}) => {
  const { id, field } = useInput({ source, validate });

  return (
    <TextField
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
      }}
      fullWidth={fullWidth}
      id={id}
      inputProps={{
        step: ".01",
      }}
      label="euro"
      type="number"
      value={field.value ? field.value / 100 : null}
      onBlur={field.onBlur}
      onChange={(e) =>
        field.onChange(Math.round(parseFloat(e.target.value.toString()) * 100))
      }
    />
  );
};

export default MoneyInput;
