import { Button, ButtonProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React, { ReactElement, ReactNode, forwardRef } from "react";

interface DialogButtonProps {
  buttonProps?: Partial<ButtonProps>;
  children?: ReactNode;
  fullWidth: boolean;
  icon?: ReactElement;
  setShowDialog: (showDialog: boolean) => void;
  showDialog: boolean;
  title: string;
}

// eslint-disable-next-line react/display-name
const DialogButton = forwardRef<HTMLButtonElement, DialogButtonProps>(
  (props, ref) => {
    const {
      buttonProps,
      children,
      fullWidth = false,
      icon,
      setShowDialog,
      showDialog,
      title,
    } = props;
    const handleClick = () => {
      setShowDialog(true);
    };

    const handleCloseClick = () => {
      setShowDialog(false);
    };

    return (
      <>
        <Button
          fullWidth={fullWidth}
          ref={ref}
          startIcon={icon}
          onClick={handleClick}
          {...buttonProps}
        >
          {title}
        </Button>
        <Dialog
          fullWidth
          aria-label={title}
          maxWidth="lg"
          open={showDialog}
          onClose={handleCloseClick}
        >
          <DialogTitle>{title}</DialogTitle>
          {children}
        </Dialog>
      </>
    );
  }
);

export default DialogButton;
