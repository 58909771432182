import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ToastuiEditor from "@toast-ui/editor";
import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useGetOne,
} from "react-admin";
import { useWatch } from "react-hook-form";

import { DatapointData } from "../../../types/models";
import { DatapointType } from "../resources/datapoints/lib/types";

const FORMAT_OPTIONS: Record<DatapointType, Record<string, string>[]> = {
  [DatapointType.String]: [{ id: "default", name: "default" }],
  [DatapointType.NumberRange]: [{ id: "default", name: "default" }],
  [DatapointType.MoneyRange]: [{ id: "euro", name: "euro" }],
  [DatapointType.DateRange]: [{ id: "date", name: "date" }],
  [DatapointType.Options]: [{ id: "default", name: "default" }],
  [DatapointType.Boolean]: [{ id: "default", name: "default" }],
  [DatapointType.PensionProvider]: [{ id: "default", name: "default" }],
};

const FormatInput = () => {
  const datapointId = useWatch({ name: "datapoint_id" });
  const { data: datapoint } = useGetOne<DatapointData>(
    "datapoints",
    {
      id: datapointId!,
    },
    { enabled: !!datapointId }
  );

  if (!datapoint) {
    return null;
  }

  return (
    <SelectInput
      fullWidth
      choices={FORMAT_OPTIONS[datapoint.datapoint_type]}
      label="Format"
      source="format"
      validate={required()}
    />
  );
};

const DatapointInterpolationDialog = ({
  editor,
  handleClose,
  show,
}: {
  editor?: ToastuiEditor;
  handleClose: () => void;
  show: boolean;
}) => {
  const handleSubmit = (values: Record<string, string | number>) => {
    if (editor) {
      editor.insertText(`{{${values.datapoint_id}=${values.format}}}`);
    }
    handleClose();
  };

  return (
    <Dialog
      aria-label="Insert datapoint"
      maxWidth="md"
      open={show}
      onClose={handleClose}
    >
      <DialogTitle>Insert datapoint</DialogTitle>
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          reference="datapoints"
          sort={{ field: "slug", order: "ASC" }}
          source="datapoint_id"
        >
          <AutocompleteInput
            fullWidth
            label="Datapoint"
            optionText="slug"
            validate={required()}
          />
        </ReferenceInput>
        <FormatInput />
      </SimpleForm>
    </Dialog>
  );
};

export default DatapointInterpolationDialog;
