import React from "react";
import {
  Datagrid,
  DateField,
  DeleteButton,
  NumberField,
  useRecordContext,
} from "react-admin";

import { VariableValueData } from "../../../../../types/models";
import { FormType } from "../../../components/CreateOrEdit";
import { formatEuro } from "../../../lib/numbers";
import { VariableType } from "../../variables/lib/types";

import VariableValueFormButton from "./VariableValueFormButton";

const ValueField = () => {
  const record = useRecordContext<VariableValueData>();
  if (!record) return null;

  switch (record.variable_type) {
    case VariableType.Date:
      return <DateField fullWidth source="date" />;

    case VariableType.DateArray:
      return (
        <ul>
          {record.date_array?.map((item, index) => {
            const datum = new Date(item);

            if (!datum) return null;

            return <li key={"date" + index}>{datum.toLocaleDateString()}</li>;
          })}
        </ul>
      );

    case VariableType.Money:
      return <div>{formatEuro(record.number / 100)}</div>;

    case VariableType.MoneyArray:
      return (
        <ul>
          {record.number_array?.map((item, index) => {
            return <li key={"number" + index}>{formatEuro(item / 100)}</li>;
          })}
        </ul>
      );

    case VariableType.NumberArray:
      return (
        <ul>
          {record.number_array?.map((item, index) => {
            return <li key={"number" + index}>{item.toLocaleString()}</li>;
          })}
        </ul>
      );

    default:
      return <NumberField fullWidth source="number" />;
  }
};

const VariableValueDatagrid = () => (
  <Datagrid bulkActionButtons={false}>
    <ValueField />
    <DateField source="start_date" />
    <DateField source="end_date" />
    <VariableValueFormButton type={FormType.EditForm} />
    <DeleteButton mutationMode="pessimistic" redirect={false} />
  </Datagrid>
);

export default VariableValueDatagrid;
