import { Button, ButtonGroup } from "@mui/material";
import React, { useState } from "react";
import {
  RadioButtonGroupInput,
  TextInput,
  useRecordContext,
} from "react-admin";

import { NodeAnswerButtonData } from "../../../../../types/models";

const Boolean = () => {
  const record = useRecordContext<NodeAnswerButtonData>();
  const [bdChecker, setBdChecker] = useState(!!record?.bd_checker_url);

  return (
    <>
      <ButtonGroup>
        <Button
          variant={bdChecker ? "outlined" : "contained"}
          onClick={() => setBdChecker(false)}
        >
          Boolean
        </Button>
        <Button
          variant={bdChecker ? "contained" : "outlined"}
          onClick={() => setBdChecker(true)}
        >
          Belastingdienst checker
        </Button>
      </ButtonGroup>
      {bdChecker ? (
        <TextInput fullWidth source="bd_checker_url" />
      ) : (
        <RadioButtonGroupInput
          choices={[
            { id: true, name: "true" },
            { id: false, name: "false" },
            { id: "", name: "unknown" },
          ]}
          source="boolean"
        />
      )}
    </>
  );
};

export default Boolean;
