import React from "react";
import { Datagrid, List, TextField } from "react-admin";

const DatapointList = () => (
  <List perPage={100} sort={{ field: "slug", order: "ASC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="slug" />
      <TextField source="datapoint_type" />
      <TextField label="Nodes" source="nodes_count" />
      <TextField label="Calculations" source="calculations_count" />
      <TextField label="Conditions" source="conditions_count" />
    </Datagrid>
  </List>
);

export default DatapointList;
